.modal__background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 4;
}

.modal__dialog {
    width: 64%;
    margin: 0 auto;
    box-shadow: 0 22px 14px -15px rgba(0, 0, 0, 0.15);
    background-color: rgb(255, 255, 255);
    bottom: 82px;
    top: 82px;
    left: 18%;
    position: fixed;
    z-index: 5;
}

.modal__dialog__header {
    margin-top: 22px;
}

.modal__dialog__header .section-title {
    font-size: 30px;
}

.modal__dialog__header__close {
    position: relative;
    float: right;
    cursor: pointer;
    width: 20px;
    height: 60px;
    right: 17px;
    top: -23px;
    z-index: 1;
}

.modal__dialog__header__close:before,
.modal__dialog__header__close:after {
    content: '';
    position: absolute;
    right: 9px;
    top: 20px;
    width: 2px;
    height: 25px;
    background-color: rgb(51, 51, 51);
}

.modal__dialog__header__close:before {
    transform: rotate(-45deg);
}

.modal__dialog__header__close:after {
    transform: rotate(45deg);
}

.modal__dialog__body__content {
    height: 81%;
    margin-top: 12px;
    overflow-y: auto;
    padding: 0 12px 56px 12px;
    width: 100%;
    text-align: justify;
}

.modal__dialog__body__content .top-section-title__text {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.75;
    margin-top: 40px;
    text-align: center;
}

.modal__dialog__body__content table {
    width: 100%;
}

.modal__dialog__body__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@media all and (max-width: 1200px) {
    .modal__dialog {
        width: 80%;
        left: 10%;
    }

    .modal__dialog__header .section-title {
        font-size: 26px;
    }
}

@media all and (max-width: 992px) {
    .modal__dialog {
        bottom: 73px;
        left: 12px;
        padding: 0;
        right: 12px;
        top: 73px;
        width: auto;
    }

    .modal__dialog__header .section-title {
        font-size: 22px;
    }

    .modal__dialog__header__close {
        right: 8px;
        width: 30px;
    }

    .modal__dialog__header__close:before,
    .modal__dialog__header__close:after {
        right: 15px;
        top: 23px;
    }

    .modal__dialog__body__content {
        padding-bottom: 36px;
        text-align: start;
    }
}

@media all and (max-width: 767px) {
    .modal__background {
        width: 100%;
    }

    .modal__dialog {
        height: auto;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        width: 100%;
    }

    .modal__dialog__header {
        margin-top: 42px;
    }

    .modal__dialog__header__close {
        height: 43px;
        right: 17px;
        top: -45px;
        width: 30px;
    }

    .modal__dialog__header__close:before,
    .modal__dialog__header__close:after {
        right: 13px;
        top: 16px;
        height: 23px;
    }

    .modal__dialog__body__content {
        height: 82%;
        padding-bottom: 10px;
    }

    .modal__dialog__body__content .top-section-title__text {
        font-size: 16px;
        font-weight: bold;
        margin-top: 30px;
    }
}
