.search-results-view .pagination {
    margin-top: 30px;
}

.search-results-view .all-news-item {
    height: auto;
}

.search-results-view .all-news-item__image {
    width: 180px;
    height: 180px;
}

.search-results-view__message {
    color: rgb(224, 27, 34);
    margin-top: 10px;
    font-size: 14px;
}

@media all and (max-width: 992px) {
    .search-results-view .all-news-item {
        height: 100px;
    }

    .search-results-view .all-news-item__image {
        width: 100px;
        height: 100px;
    }
}
