.RC-header {
    width: 100%;
    background-color: rgb(5, 47, 105);
    height: 210px;
}

.RC-header .container-1280 {
    height: 100%;
    width: 97%;
    display: flex;
    justify-content: space-between;
    padding: 50px 0 0 0;
    color: rgb(255, 255, 255);
}

.RC-header__logo {
    width: 16%;
    margin-top: -5px;
}

.RC-header__logo img {
    width: 100%;
}

.RC-header__content {
    width: 60%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 40px;
}

.RC-header__content__chamber-name {
    text-decoration: none;
    color: white;
}

.RC-header__content__chamber-name:hover {
    color: rgb(224, 27, 34);
}

.RC-header__content__chamber-name h1 {
    font-size: 52px;
    line-height: 1;
}

.RC-header__content__chamber-name h3 {
    font-size: 21px;
    margin-top: 5px;
    font-weight: normal;
}

.RC-header__content__navigation {
    display: flex;
    list-style-type: none;
    margin-bottom: 15px;
}

.RC-header__content__navigation a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 7px;
}

.RC-header__content__navigation > li {
    margin-right: 40px;
}

.RC-header__content__navigation li:last-of-type {
    margin-right: 0;
    margin-left: auto;
}

.RC-header__content__navigation a:hover,
.RC-header__content__navigation a.is-active-link {
    border-bottom: 1px solid rgb(255, 255, 255);
}

.RC-header__content__language {
    display: flex;
    list-style-type: none;
    height: 100%;
    align-items: flex-end;
}

.RC-header__content__language li {
    height: 20px;
    font-size: 15px;
}

.RC-header__content__language li:first-of-type {
    margin-right: 15px;
}

.RC-header__content__language li:nth-of-type(1) {
    border-right: 1px solid rgb(255, 255, 255);
    padding-right: 15px;
}

.RC-header__content__language li:hover {
    cursor: pointer;
}

.RC-header__content__language li.active-language {
    color: rgb(224, 27, 34);
}

.RC-header__image {
    height: 140px;
    align-self: flex-end;
}

.RC-header__image img {
    height: 100%;
}

.RC-header__menu {
    display: none;
}

.RC-header__logo--mobile {
    display: none;
}

@media all and (max-width: 1248px) {
    .RC-header__content__navigation > li {
        margin-right: 20px;
    }

    .RC-header__content__navigation a {
        font-size: 14px;
    }

    .RC-header__image {
        height: 120px;
    }
}

@media all and (max-width: 1100px) {
    .RC-header__content__navigation > li {
        margin-right: 12px;
    }
}

@media all and (max-width: 1040px) {
    .RC-header__content h1 {
        font-size: 42px;
    }

    .RC-header__content h3 {
        font-size: 16px;
    }

    .RC-header__content__navigation li {
        margin-right: 25px;
    }
}

@media all and (max-width: 1024px) {
    .RC-header {
        position: relative;
    }

    .RC-header .container-1280 {
        align-items: center;
        padding: 0;
    }

    .RC-header__logo {
        margin-bottom: 32px;
    }

    .RC-header__content__chamber-name:hover {
        color: white;
    }

    .RC-header__content {
        justify-content: flex-start;
        margin: 0 20px;
    }

    .RC-header__content > div {
        display: flex;
    }

    .RC-header__content h1 {
        font-size: 42px;
        letter-spacing: -4px;
        line-height: 70px;
        /* margin: 40px auto; */
        margin: 0;
    }

    .RC-header__content h3 {
        display: none;
    }

    .RC-header__content__navigation {
        display: none;
    }

    .RC-header__image {
        height: 127px;
    }

    .RC-header__menu {
        align-self: center;
        display: block;
        margin-left: 20px;
    }

    .RC-header__menu__hamburger {
        height: 17px;
        width: 28px;
    }

    .RC-header__menu--close .RC-header__menu__close-menu {
        display: none;
    }

    .RC-header__menu--open .RC-header__menu__close-menu {
        display: inline;
    }

    .RC-header__menu--open .RC-header__menu__hamburger {
        display: none;
    }
}

@media all and (max-width: 767px) {
    .RC-header {
        position: fixed;
        z-index: 2;
        height: 75px;
    }

    .RC-header .container-1280 {
        /* align-items: center; */
        /* padding: 15px 15px 0 15px; */
    }

    .RC-header__logo {
        width: 40px;
        margin: 0 0 15px 0;
    }

    .RC-header__logo--large {
        display: none;
    }

    .RC-header__logo--mobile {
        display: inline-block;
        width: 100%;
    }

    .RC-header__content h1 {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 21px;
        /* margin: 0 0 15px 20px; */
        padding: 0;
    }

    .RC-header__image {
        height: 50px;
        /* align-self: flex-end; */
    }

    .RC-header__menu {
        margin-bottom: 15px;
    }
}
