.details-link {
    text-align: right;
}

.details-link__text {
    margin: 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: rgb(5, 47, 105);
    position: relative;
    display: inline-block;
    padding: 0 1px;
    transition: color ease 0.3s;
}

.details-link__text.details-link__text--white {
    color: rgb(255, 255, 255);
}

.details-link__text::before,
.details-link__text::after {
    content: '';
    position: absolute;
    bottom: 0px;
    background-color: rgb(5, 47, 105);
    z-index: 1;
    height: 2px;
}

.details-link__text::before {
    width: 0%;
    left: 0;
    bottom: -2px;
    transition: width ease 0.4s;
}

.details-link__text::after {
    width: 100%;
    left: 0;
    bottom: -2px;
    transition: all ease 0.6s;
}

.details-link__text.details-link__text--white::before,
.details-link__text.details-link__text--white::after {
    background-color: rgb(255, 255, 255);
}

.details-link__text:hover::before {
    width: 100%;
}

.details-link__text:hover::after {
    left: 100%;
    width: 0%;
    transition: all ease 0.2s;
}

@media all and (max-width: 992px) {
    .details-link {
        text-align: center;
    }

    .details-link__text {
        font-size: 14px;
        letter-spacing: 1.3px;
    }

    .details-link__text::before,
    .details-link__text::after {
        z-index: 1;
    }
}
