.rc-all-news {
    padding: 87px 0 105px 0;
    background-color: rgb(5, 47, 105);
}

.rc-all-news__content.container-1280 {
    width: 97%;
    margin: 54px auto 90px auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 24px;
}

@media all and (max-width: 992px) {
    .rc-all-news {
        padding: 74px 12px 65px 12px;
    }

    .rc-all-news__content.container-1280 {
        margin: 35px auto 50px auto;
        padding: 0;
    }
}

@media all and (max-width: 767px) {
    .rc-all-news {
        padding: 32px 12px 46px 12px;
    }

    .rc-all-news__content.rc-all-news__content.container-1280 {
        flex-direction: column;
        margin-top: 15px;
        margin-bottom: 50px;
        padding: 0;
    }
}
