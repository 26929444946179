.loader-container {
    height: 400px;
    display: flex;
}

.loader {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin: auto;
}

.loader__one,
.loader__two,
.loader__three,
.loader__four {
    width: 30px;
    animation: loader 2s infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0;
}

.loader__two {
    animation-delay: 500ms;
}

.loader__three {
    animation-delay: 1500ms;
}

.loader__four {
    animation-delay: 1s;
}

@keyframes loader {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
}
