.data-protection p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.44;
    text-align: justify;
}

.data-protection h3 {
    color: rgb(5, 47, 105);
    font-size: 26px;
    margin: 40px auto;
    text-align: center;
}

.data-protection ul {
    list-style-type: none;
    margin-bottom: 20px;
}

.data-protection li {
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.44;
    text-align: justify;
}

/* .data-protection  ol,
.data-protection  ul {
    margin-bottom: 20px;
    list-style-type: none;
}

.data-protection  li {
    margin-left: 30px;
    margin-top: 10px;
    position: relative;
    font-size: 18px;
    line-height: 1.44;
}

.data-protection  ol li {
    counter-increment: list;
    list-style-type: none;
    position: relative;
}

.data-protection  ol li:before {
    color: rgb(224, 27, 34);
    content: counter(list) '.';
    left: -32px;
    position: absolute;
    text-align: right;
    width: 26px;
}

.data-protection  ul li:before {
    content: '•';
    color: rgb(224, 27, 34);
    position: absolute;
    left: -15px;
    top: 2px;
} */

@media all and (max-width: 992px) {
    .data-protection p {
        margin-bottom: 15px;
        font-size: 16px;
    }

    .data-protection h3 {
        font-size: 22px;
    }

    .data-protection li {
        font-size: 16px;
    }
}

@media all and (max-width: 767px) {
    .data-protection p {
        font-size: 14px;
        text-align: left;
    }

    .data-protection li {
        font-size: 14px;
    }

    .data-protection h3 {
        color: rgb(5, 47, 105);
        font-size: 18px;
        margin: 40px auto;
        text-align: center;
    }
}
