.rc-top-news.container-1280 {
    width: 97%;
    padding: 30px 0 5px 0;
    position: relative;
    z-index: 1;
}

.rc-top-news__content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.rc-top-news__content__left {
    width: 54%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rc-top-news__content__left.rc-top-news__content__left--centered {
    margin: auto;
}

.rc-top-news__content__right {
    height: 548px;
    width: 45%;
}

@media all and (max-width: 992px) {
    .rc-top-news.container-1280 {
        padding: 20px 20px 5px 25px;
    }

    .rc-top-news__content {
        margin-top: 15px;
    }

    .rc-top-news__content__right {
        height: 336px;
    }
}

@media all and (max-width: 767px) {
    .rc-top-news.container-1280 {
        padding: 20px 12px 5px 12px;
        margin: 0 auto;
        width: 100%;
    }

    .rc-top-news__content {
        flex-direction: column;
        margin-top: 15px;
    }

    .rc-top-news__content__left {
        width: 100%;
    }

    .rc-top-news__content__right {
        width: 100%;
        height: auto;
    }
}
