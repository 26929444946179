.rc-page-info {
    height: 300px;
    padding: 30px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgb(247, 247, 247);
}

.rc-page-info .section-title-RC {
    font-size: 40px;
    text-align: center;
}

.rc-page-info .container-1280 {
    width: 97%;
    height: 100%;
}

.rc-page-info.rc-page-info--overlay {
    position: relative;
}

.rc-page-info.rc-page-info--overlay:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(5, 47, 105, 0.6);
}

.rc-page-info.rc-page-info--overlay .rc-page-info__content {
    position: relative;
    z-index: 1;
}

.rc-page-info__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 100px;
    height: 100%;
    align-items: center;
}

@media all and (max-width: 992px) {
    .rc-page-info {
        height: 250px;
    }

    .rc-page-info__content {
        padding: 0 0 0 25px;
    }

    .rc-page-info .section-title-RC {
        font-size: 30px;
    }
}

@media all and (max-width: 767px) {
    .rc-page-info {
        height: auto;
    }

    .rc-page-info .section-title-RC {
        font-size: 20px;
    }
}
