.events-list {
    margin-top: 50px;
}

.events-list__content {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    /* margin-top: 70.5px; */
    margin-bottom: 44px;
}

.events-list__content.events-list_content--center {
    justify-content: center;
}

@media all and (max-width: 992px) {
    .events-list {
        margin-top: 30px;
    }
}

@media all and (max-width: 767px) {
    .events-list {
        margin-top: 20px;
    }
}
