@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=cyrillic,cyrillic-ext,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.header-nav__nav {
    display: -webkit-flex;
    display: flex;

    list-style-type: none;
    height: 100%;
}

.header-nav__nav a {
    text-decoration: none;
    color: rgb(5, 47, 105);
    font-size: 18px;
    font-weight: 500;
    margin: 0 25px;
}

.header-nav__nav a:hover,
.header-nav__nav a.is-active {
    color: rgb(224, 27, 34);
}

@media all and (max-width: 1195px) {
    .header-nav__nav a {
        margin: 0 16px;
    }

    .header-nav__nav li:first-of-type a {
        margin-left: 0;
    }

    .header-nav__nav li:last-of-type a {
        margin-right: 0;
    }
}

@media all and (max-width: 1080px) {
    .header-nav__nav a {
        font-size: 18px;
        margin: 0 12px;
    }

    .header-nav__nav li:first-of-type a {
        margin-left: 0;
    }

    .header-nav__nav li:last-of-type a {
        margin-right: 0;
    }
}

@media all and (max-width: 1040px) {
    .header-nav__nav a {
        font-size: 17px;
        margin: 0 10px;
    }
}

@media all and (max-width: 998px) {
    .header-nav__nav a {
        font-size: 16px;
    }

    .header-nav__nav a:hover {
        color: rgb(5, 47, 105);
    }
}

@media all and (max-width: 992px) {
    .header-nav__nav a {
        font-size: 14px;
        margin: 0 10px;
    }
}

@media all and (max-width: 970px) {
    .header-nav__nav a {
        font-size: 13px;
        margin: 0 8px;
    }
}

@media all and (max-width: 883px) {
    .header-nav__nav a {
        font-size: 11px;
    }
}

@media all and (max-width: 819px) {
    .header-nav__nav a {
        margin: 0 7px;
    }
}

.hamburger-navigation-list {
    display: none;
}

@media all and (max-width: 767px) {
    .hamburger-navigation-list {
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        background-color: rgb(255, 255, 255);
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: center;
                align-items: center;
        padding-bottom: 13px;
    }

    .hamburger-navigation-list__nav {
        margin-top: 15px;
        list-style-type: none;
        width: 100%;
        margin-bottom: 25px;
    }

    .hamburger-navigation-list__nav li {
        margin-top: 30px;
        text-align: center;
    }

    .hamburger-navigation-list__nav li a {
        color: rgb(19, 67, 131);
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
    }

    .hamburger-navigation-list__nav .is-active {
        color: rgb(224, 27, 34);
    }

    .hamburger-navigation-list__options {
        display: -webkit-flex;
        display: flex;
        list-style-type: none;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-top: 70px;
    }

    .hamburger-navigation-list__options:hover {
        cursor: pointer;
    }

    .hamburger-navigation-list__options li {
        font-size: 16px;
        color: rgb(19, 67, 131);
        text-align: center;
        font-weight: 500;
    }

    .hamburger-navigation-list__options li.active-language {
        color: rgb(224, 27, 34);
    }

    .hamburger-navigation-list__options li {
        border-right: 1px solid rgb(203, 203, 203);
        margin-right: 24px;
        padding-right: 24px;
    }

    .hamburger-navigation-list__call-center {
        color: rgb(19, 67, 131);
        font-size: 20px;
        font-weight: 500;
    }
}

.search-button {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: top;
            align-items: top;
}

.search-button:hover {
    color: rgb(224, 27, 34);
    cursor: pointer;
}

.section-title {
    color: rgb(5, 47, 105);
    font-size: 50px;
    font-weight: 300;
    padding-bottom: 15px;
}

.section-title.dark {
    color: rgb(255, 255, 255);
}

.title-underline {
    position: relative;
}

.title-underline:after {
    content: '';
    width: 56px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

@media all and (max-width: 992px) {
    .section-title {
        font-size: 36px;
        padding-bottom: 10px;
    }

    .title-underline:after {
        width: 40px;
    }
}

@media all and (max-width: 767px) {
    .section-title {
        font-size: 28px;
        padding-bottom: 7px;
    }

    .title-underline:after {
        width: 30px;
    }
}

.modal__background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 4;
}

.modal__dialog {
    width: 64%;
    margin: 0 auto;
    box-shadow: 0 22px 14px -15px rgba(0, 0, 0, 0.15);
    background-color: rgb(255, 255, 255);
    bottom: 82px;
    top: 82px;
    left: 18%;
    position: fixed;
    z-index: 5;
}

.modal__dialog__header {
    margin-top: 22px;
}

.modal__dialog__header .section-title {
    font-size: 30px;
}

.modal__dialog__header__close {
    position: relative;
    float: right;
    cursor: pointer;
    width: 20px;
    height: 60px;
    right: 17px;
    top: -23px;
    z-index: 1;
}

.modal__dialog__header__close:before,
.modal__dialog__header__close:after {
    content: '';
    position: absolute;
    right: 9px;
    top: 20px;
    width: 2px;
    height: 25px;
    background-color: rgb(51, 51, 51);
}

.modal__dialog__header__close:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.modal__dialog__header__close:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.modal__dialog__body__content {
    height: 81%;
    margin-top: 12px;
    overflow-y: auto;
    padding: 0 12px 56px 12px;
    width: 100%;
    text-align: justify;
}

.modal__dialog__body__content .top-section-title__text {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.75;
    margin-top: 40px;
    text-align: center;
}

.modal__dialog__body__content table {
    width: 100%;
}

.modal__dialog__body__content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@media all and (max-width: 1200px) {
    .modal__dialog {
        width: 80%;
        left: 10%;
    }

    .modal__dialog__header .section-title {
        font-size: 26px;
    }
}

@media all and (max-width: 992px) {
    .modal__dialog {
        bottom: 73px;
        left: 12px;
        padding: 0;
        right: 12px;
        top: 73px;
        width: auto;
    }

    .modal__dialog__header .section-title {
        font-size: 22px;
    }

    .modal__dialog__header__close {
        right: 8px;
        width: 30px;
    }

    .modal__dialog__header__close:before,
    .modal__dialog__header__close:after {
        right: 15px;
        top: 23px;
    }

    .modal__dialog__body__content {
        padding-bottom: 36px;
        text-align: start;
    }
}

@media all and (max-width: 767px) {
    .modal__background {
        width: 100%;
    }

    .modal__dialog {
        height: auto;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        width: 100%;
    }

    .modal__dialog__header {
        margin-top: 42px;
    }

    .modal__dialog__header__close {
        height: 43px;
        right: 17px;
        top: -45px;
        width: 30px;
    }

    .modal__dialog__header__close:before,
    .modal__dialog__header__close:after {
        right: 13px;
        top: 16px;
        height: 23px;
    }

    .modal__dialog__body__content {
        height: 82%;
        padding-bottom: 10px;
    }

    .modal__dialog__body__content .top-section-title__text {
        font-size: 16px;
        font-weight: bold;
        margin-top: 30px;
    }
}

.top-section-title {
    margin-bottom: 50px;
}

.top-section-title--centered {
    text-align: center;
}

.top-section-title__text {
    color: rgb(255, 255, 255);
    font-size: 50px;
    font-weight: 300;
    padding-bottom: 15px;
}

.top-section-title__text.dark {
    color: rgb(5, 47, 105);
}

.top-section-title__text--underlined {
    position: relative;
}

.top-section-title__text--underlined:after {
    content: '';
    width: 56px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.top-section-title--centered .top-section-title__text--underlined:after {
    left: calc(50% - 28px);
}

@media all and (max-width: 992px) {
    .top-section-title {
        margin-bottom: 30px;
    }

    .top-section-title__text {
        font-size: 36px;
        padding-bottom: 10px;
    }

    .top-section-title__text--underlined:after {
        width: 40px;
    }

    .top-section-title--centered .top-section-title__text--underlined:after {
        left: calc(50% - 20px);
    }
}

@media all and (max-width: 767px) {
    .top-section-title {
        margin-bottom: 20px;
    }

    .top-section-title__text {
        font-size: 28px;
        padding-bottom: 7px;
    }

    .top-section-title__text--underlined:after {
        width: 30px;
    }

    .top-section-title--centered .top-section-title__text--underlined:after {
        left: calc(50% - 15px);
    }
}

/* .section-button {
    display: flex;
    justify-content: center;
} */

.section-button button {
    padding: 15px 60px;
    background: transparent;
    border: 1px solid rgb(5, 47, 105);
    box-shadow: none;
    font-size: 16px;
    color: rgb(5, 47, 105);
    cursor: pointer;
}

.section-button button:hover {
    background: rgb(5, 47, 105);
    color: rgb(255, 255, 255);
}

.section-button.section-button--dark button {
    border: 1px solid rgb(255, 255, 255);
    margin: 25px 0;
    padding: 14px 52px;
    color: rgb(255, 255, 255);
}

.section-button.section-button--dark button:hover {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

.section-button button:focus {
    outline: none;
}

.section-button__icon {
    margin-right: 20px;
    margin-left: -10px;
}

@media all and (max-width: 992px) {
    .section-button button {
        font-size: 14px;
        line-height: 17px;
        padding: 12px 37px;
    }

    .section-button.section-button--dark button {
        margin: 0;
    }

    .section-button button:hover {
        box-shadow: none;
    }

    .section-button button:hover {
        background-color: rgb(5, 47, 105);
    }
}

@media all and (max-width: 767px) {
    .section-button button {
        font-size: 13px;
        padding: 12px 30px;
    }
}

.all-news-item {
    width: 100%;
    height: 230px;
    margin-top: 40px;
    overflow: hidden;
}

.all-news-item a {
    display: -webkit-flex;
    display: flex;
}

.all-news-item__image {
    width: 230px;
    height: 230px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
}

.all-news-item__content {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.all-news-item__content h5 {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 11px;
}

.all-news-item--dark .all-news-item__content h5 {
    color: rgb(5, 47, 105);
}

.all-news-item:hover h5 {
    color: rgb(224, 27, 34);
}

.all-news-item__content h6 {
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-weight: lighter;
    position: relative;
    padding-bottom: 15px;
    letter-spacing: -0.4px;
}

.all-news-item--dark .all-news-item__content h6 {
    color: rgb(5, 47, 105);
    font-weight: normal;
}

.all-news-item__content h6:after {
    content: '';
    width: 36px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.all-news-item__content p {
    color: rgb(255, 255, 255);
    font-weight: lighter;
    font-size: 16px;
    margin-top: 18px;
}

.all-news-item--dark .all-news-item__content p {
    color: rgb(51, 51, 51);
    font-weight: normal;
}

.all-news-item__content p b {
    font-weight: lighter;
}

.all-news-item a,
.all-news-item a:visited {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

@media all and (max-width: 992px) {
    .all-news-item {
        height: 100px;
        margin-top: 17px;
    }

    .all-news-item__image {
        width: 100px;
        height: 100px;
        margin-right: 14px;
    }

    .all-news-item__content h5 {
        font-size: 16px;
        padding-bottom: 9px;
        margin-top: 0;
        line-height: 1.31;
    }

    .all-news-item:hover h5 {
        color: rgb(255, 255, 255);
    }

    .all-news-item--dark:hover h5 {
        color: rgb(5, 47, 105);
    }

    .all-news-item__content h6 {
        padding-bottom: 0;
    }

    .all-news-item__content h6:after {
        height: 0;
    }
}

@media all and (max-width: 767px) {
    .all-news-item {
        width: 100%;
        height: 80px;
        margin-top: 15px;
    }

    .all-news-item__image {
        width: 80px;
        height: 80px;
        margin-right: 10px;
    }

    .all-news-item__content h5 {
        font-size: 14px;
        padding-bottom: 5px;
    }

    .all-news-item__content h6 {
        font-size: 12px;
        letter-spacing: -0.3px;
    }

    .all-news-item__content p {
        display: none;
    }
}

.date-event-item {
    color: rgb(5, 47, 105);
    display: block;
    text-decoration: none;
    padding: 5px;
    border-bottom: solid 1px rgba(5, 47, 105);
    margin: 20px 0;
}

.date-event-item--dark {
    color: white;
    border-bottom: 1px solid white;
}

.date-event-item:hover {
    border-bottom: solid 1px rgba(224, 27, 34);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}

.date-event-item:hover .date-event-item__title {
    color: rgb(224, 27, 34);
}

.date-event-item__info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.date-event-item__info__date {
    position: relative;
    font-size: 16px;
}

.date-event-item__info__date:after {
    content: '';
    width: 39px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    top: 85%;
    left: 0;
}

.date-event-item__info__label {
    font-size: 14px;
}

.date-event-item__info__label {
    padding: 5px 20px;
    border-radius: 30px;
    display: -webkit-flex;
    display: flex;
    text-align: center;
}

.date-event-item__info__label div {
    margin: auto;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 13px;
}

.date-event-item__title {
    font-size: 16px;
    line-height: 1.48;
    margin: 10px 0;
}

@media all and (max-width: 767px) {
    .date-event-item__info__label {
        font-size: 12px;
    }

    .date-event-item__title {
        font-size: 14px;
        line-height: 1.48;
        margin: 10px 0;
    }

    .date-event-item__info__date {
        font-size: 14px;
    }
}

.pagination {
    width: 230px;
    height: 40px;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pagination__left,
.pagination__page,
.pagination__right {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
}

.pagination__left:hover,
.pagination__right:hover {
    cursor: pointer;
}

.pagination__left__arrow,
.pagination__right__arrow {
    border: solid rgb(255, 255, 255);
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: auto;
}

.pagination__left__arrow {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.pagination__right__arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.pagination__page__number {
    width: 40px;
    height: 36px;
    line-height: 36px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    margin: auto;
}

.pagination__page__number:hover {
    cursor: pointer;
}

.current-page .pagination__page__number {
    color: rgb(224, 27, 34);
}

.disable-pag {
    opacity: 0.3;
}

.pagination__left__arrow.light-pag,
.pagination__right__arrow.light-pag {
    border: solid rgb(5, 47, 105);
    border-width: 0 2.5px 2.5px 0;
}

.pagination__page__number.light-pag {
    color: rgb(5, 47, 105);
}

.current-page .pagination__page__number.light-pag {
    color: rgb(224, 27, 34);
}

@media all and (max-width: 992px) {
    .pagination {
        width: 100%;
        max-width: 288px;
        margin-bottom: 30px;
    }

    .pagination__left,
    .pagination__page,
    .pagination__right {
        border: solid 1px rgb(5, 47, 105);
        background-color: rgb(26, 89, 174);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .pagination__left__arrow,
    .pagination__right__arrow {
        width: 10px;
        height: 10px;
        position: relative;
    }

    .pagination__left__arrow {
        left: 2px;
    }

    .pagination__right__arrow {
        right: 2px;
    }

    .pagination__page__number {
        font-size: 15px;
    }

    .current-page {
        border: 2px solid rgb(224, 27, 34);
        background-color: rgb(255, 255, 255);
    }

    .pagination__left.light-pag,
    .pagination__page.light-pag,
    .pagination__right.light-pag {
        border: solid 1px rgb(228, 228, 228);
        background-color: rgb(247, 247, 247);
    }

    .current-page.light-pag {
        border: 2px solid rgb(224, 27, 34);
        background-color: rgb(255, 255, 255);
    }
}

.search-results-view .pagination {
    margin-top: 30px;
}

.search-results-view .all-news-item {
    height: auto;
}

.search-results-view .all-news-item__image {
    width: 180px;
    height: 180px;
}

.search-results-view__message {
    color: rgb(224, 27, 34);
    margin-top: 10px;
    font-size: 14px;
}

@media all and (max-width: 992px) {
    .search-results-view .all-news-item {
        height: 100px;
    }

    .search-results-view .all-news-item__image {
        width: 100px;
        height: 100px;
    }
}

.search-modal-view {
    padding: 0 40px;
}

.search-modal-view .top-section-title h3 {
    margin: 0;
}

.search__search {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.search__search__bar {
    width: 70%;
    padding: 15px 30px;
    border: none;
    font-size: 16px;
    color: rgb(5, 47, 105);
    border-bottom: 1px solid rgb(5, 47, 105);
}

.search__search__bar:focus {
    outline: none;
}

.search__search__bar::-webkit-input-placeholder {
    font-size: 16px;
    color: rgb(5, 47, 105);
}

.search__search__bar::placeholder {
    font-size: 16px;
    color: rgb(5, 47, 105);
}

.search__search__button {
    width: 100px;
    text-align: center;
    color: rgb(5, 47, 105);
    font-size: 20px;
}

.search__search__button:hover {
    color: rgb(224, 27, 34);
    cursor: pointer;
}

@media all and (max-width: 767px) {
    .search-modal-view {
        padding: 0 10px;
    }

    .search__search {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        height: 100px;
    }

    .search__search__bar {
        width: 100%;
        padding: 10px 15px;
        font-size: 14px;
    }
}

.search-no-results {
    text-align: center;
    padding: 100px 0;
    color: rgb(153, 153, 153);
}

.search-no-results__icon {
    font-size: 80px;
}

.search-no-results__text {
    font-size: 30px;
    margin-top: 50px;
}

@media all and (max-width: 767px) {
    .search-no-results {
        padding: 50px 0;
    }
    .search-no-results__icon {
        font-size: 40px;
    }

    .search-no-results__text {
        font-size: 20px;
        margin-top: 25px;
    }
}

/* .search__search {
    display: flex;
    align-items: center;
    margin: 0 40px;
    border-bottom: 1px solid rgb(5, 47, 105);
}

.search__search__bar {
    flex: 1;
    padding: 15px 30px;
    border: none;
    font-size: 16px;
    color: rgb(5, 47, 105);
}

.search__search__bar::placeholder {
    font-size: 16px;
    color: rgb(5, 47, 105);
}

.search__search__button {
    width: 100px;
    text-align: center;
    color: rgb(5, 47, 105);
    font-size: 20px;
}

.search__search__button:hover {
    color: rgb(224, 27, 34);
    cursor: pointer;
} */

.header {
    width: 100%;
    height: 100px;
    position: fixed;
    background-color: white;
    z-index: 2;
    top: 0;
    left: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.header:after {
    clear: both;
    content: '';
    display: block;
}

.header--hidden {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.header .container-1280 {
    background-color: rgb(255, 255, 255);
    position: relative;
    display: -webkit-flex;
    display: flex;
    /* flex-flow: row wrap; */
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: 12px 0 26px 0;
    z-index: 3;
    height: 100%;
}

.header__logo {
    margin-bottom: -3px;
    width: 148px;
}

.header__logo img {
    width: 100%;
}

.header__navigation {
    margin-bottom: 6px;
    width: 70%;
}

.header__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.header__content a {
    font-size: 14px;
    color: rgb(136, 136, 136);
    font-weight: normal;
    text-decoration: none;
}

.header__content a span {
    font-weight: 500;
}

.header__content a:hover {
    color: rgb(224, 27, 34);
}

.header__content__options {
    display: -webkit-flex;
    display: flex;
    list-style-type: none;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 9px;
    margin-top: 10px;
}

.header__content__options li {
    font-size: 16px;
    color: rgb(5, 47, 105);
    text-align: center;
    font-weight: 500;
}

.header__content__options li {
    border-right: 2px solid rgb(203, 203, 203);
    margin-right: 13px;
    padding-right: 13px;
}

.header__content__options li:hover {
    color: rgb(224, 27, 34);
    cursor: pointer;
}

.header__content__options li.active-language {
    color: rgb(224, 27, 34);
}

.header__search-menu {
    height: 43%;
}

.header__menu {
    display: none;
}

@media all and (max-width: 1060px) {
    .header .container-1280 {
        padding: 11px 0 18px 0;
        width: 97%;
    }

    .header__content__options {
        margin-bottom: 6px;
    }

    .header__content__options li {
        font-size: 14px;
    }

    .header__content h6 {
        font-size: 13px;
    }

    .header__logo {
        margin-bottom: 0;
        width: 129px;
    }

    .header__navigation {
        width: 65%;
    }
}

@media all and (max-width: 992px) {
    .header__content a {
        font-size: 12px;
    }

    .header__content__options li:hover {
        color: rgb(5, 47, 105);
    }

    .header__navigation {
        width: 55%;
    }
}

@media all and (max-width: 767px) {
    .header {
        height: 50px;
        position: fixed;
        z-index: 2;
    }

    .header .container-1280 {
        -webkit-align-items: center;
                align-items: center;
        width: 100%;
    }

    .header .container-1280 {
        padding: 0 14px 0 12px;
    }

    .header__logo {
        width: 88px;
    }

    .header__logo img {
        width: 100%;
    }

    .header__navigation,
    .header__content {
        display: none;
    }

    .header__search-menu {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 60px;
    }

    .header__menu {
        display: block;
        width: 24px;
    }

    .header__menu:hover {
        cursor: pointer;
    }

    .header__menu--close .header__menu__close-menu {
        display: none;
    }

    .header__menu--open .header__menu__close-menu {
        display: inline;
    }

    .header__menu--open .header__menu__hamburger {
        display: none;
    }
}

.link-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    list-style-type: none;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.link-list li {
    margin-bottom: 10px;
}

.link-list li a {
    text-decoration: none;
    color: rgb(220, 220, 220);
    font-size: 16px;
}

.link-list li a:hover {
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-bottom: 3px;
}

@media all and (max-width: 992px) {
    .link-list li a:hover {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.footer-info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.footer-info div {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 5px;
}

.footer-info h5,
.footer-info .footer-info__email {
    color: rgb(130, 155, 188);
    font-size: 16px;
    line-height: 1.43;
    font-weight: normal;
    margin: 8px 0;
}

.footer-info .footer-info__email:hover {
    color: rgb(255, 255, 255);
}

.footer-info span {
    display: block;
}

@media all and (max-width: 992px) {
    .footer-info h5 {
        font-size: 15px;
        margin: 0;
    }
}

@media all and (max-width: 767px) {
    .footer__section:nth-of-type(3) .footer-info {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .footer-info div {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .footer-info div:nth-of-type(2) {
        margin: 15px 0 25px 0;
    }

    .footer-info div:nth-of-type(3) {
        margin-bottom: 0;
    }

    .footer-info div:last-of-type h5:nth-of-type(1) {
        margin-top: 0;
    }

    .footer-info h5 {
        line-height: 18px;
        font-size: 12px;
    }
}

.footer {
    background-color: rgb(6, 55, 122);
    color: rgb(238, 238, 238);
    padding: 60px 80px 60px 80px;
}

.footer__section {
    border-bottom: 1px solid rgb(238, 238, 238);
}

.footer__section a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.footer__section:nth-of-type(1) {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-bottom: 50px;
}

.footer__section__logo {
    width: 148px;
}

.footer__section__logo img {
    width: 100%;
}

.footer__section:nth-of-type(1) .link-list {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.footer__section:nth-of-type(2),
.footer__section:nth-of-type(4) {
    padding: 40px 0 30px 0;
}

.footer__section:nth-of-type(2) .link-list {
    height: 128px;
}

.footer__section:nth-of-type(2) .link-list li {
    width: 25%;
}

.footer__section h4 {
    font-size: 26px;
    font-weight: lighter;
    margin-bottom: 40px;
}

.footer__section h4:nth-of-type(2) {
    border-bottom: 1px solid rgb(238, 238, 238);
    border-top: 1px solid rgb(238, 238, 238);
    padding: 40px 0;
}

.footer__section:nth-of-type(3) {
    border: none;
    padding-top: 40px;
}

.footer__icons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    padding: 50px 0 0 0;
}

.footer__icons img {
    margin: 0 20px;
    width: 34px;
}

.footer__privacy-policy {
    padding: 40px 0;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.footer__privacy-policy a {
    text-decoration: none;
    color: rgb(220, 220, 220);
    font-size: 16px;
    margin-bottom: 32px;
}

.footer__privacy-policy a:hover {
    color: rgb(255, 255, 255);
}

@media all and (max-width: 992px) {
    .footer {
        padding: 56px 86px 30px 86px;
    }

    .footer__section:nth-of-type(1) {
        padding-bottom: 30px;
    }

    .footer__section__logo {
        /*remove this margin if centered?*/
        margin-left: -50px;
        width: 173px;
    }

    .footer__section .link-list li {
        margin-bottom: 15px;
    }

    .footer__section .link-list li a {
        font-size: 16px;
    }

    .footer__section:nth-of-type(2),
    .footer__section:nth-of-type(4) {
        padding: 30px 0 15px 0;
    }

    .footer__section:nth-of-type(2) .link-list {
        height: 155px;
    }

    .footer__section h4 {
        font-size: 21px;
        font-weight: normal;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    .footer__section h4:nth-of-type(2) {
        padding: 30px 0;
    }

    .footer__section:nth-of-type(3) {
        padding-top: 23px;
    }

    .footer__icons {
        padding: 30px 0;
    }

    .footer__privacy-policy a {
        font-size: 14px;
    }
}

@media all and (max-width: 767px) {
    .footer {
        padding: 21px 16px;
        height: auto;
    }

    .footer__section:nth-of-type(1) {
        padding-right: 0;
        padding-bottom: 6px;
    }

    .footer__section__logo {
        margin-left: 0;
        width: 102px;
    }

    .footer__section:nth-of-type(1) .link-list {
        width: 79px;
    }

    .footer__section:nth-of-type(1) .link-list li a {
        font-size: 14px;
    }

    .footer__section:nth-of-type(2),
    .footer__section:nth-of-type(4) {
        padding-top: 20px;
        padding-bottom: 6px;
    }

    .footer__section:nth-of-type(2) .link-list {
        height: 360px;
        width: 100%;
    }

    .footer__section:nth-of-type(2) .link-list li {
        -webkit-flex: 0 1 auto;
                flex: 0 1 auto;
        margin: 10px 0;
    }

    .footer__section:nth-of-type(2) .link-list li a {
        font-size: 12px;
    }

    .footer__section h4 {
        font-size: 17px;
        margin-bottom: 20px;
    }

    .footer__section h4:nth-of-type(2) {
        margin-bottom: 5px;
        padding: 20px 0;
    }

    .footer__section:nth-of-type(3) {
        padding-top: 15px;
    }

    .footer__icons {
        padding: 5px 0 0 0;
    }

    .footer__icons img {
        margin: 10px 16px;
        width: 28px;
        height: 28px;
    }

    .footer__privacy-policy a {
        font-size: 12px;
    }
}

.cookies-table-wrapper {
    width: 100%;
    overflow-x: scroll;
}
.cookies-table-wrapper td {
    white-space: nowrap;
}
.cookies-thead {
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    font-weight: 500;
}

.gdpr-notification-container {
    width: 100%;
    padding: 10px 25px 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9000;
    background-color: rgba(0, 37, 70, 0.92);
}
@media only screen and (min-width: 969px) {
    .gdpr-notification-container {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
.gdpr-notification-txt {
    font-size: 12px;
    color: #fff;
    line-height: 16px;
}
@media only screen and (min-width: 720px) {
    .gdpr-notification-txt {
        font-size: 14px;
        line-height: 20px;
    }
}
.gdpr-notification-link {
    text-decoration: underline;
    color: #fff;
}
.gdpr-notification-link:hover {
    text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
}
.gdpr-notification-accept-btn {
    padding: 5px 25px;
    border-radius: 10px;
    color: rgb(0, 37, 70);
    background-color: #fff;
    border: 1px solid #fff;
    font-size: 14px;
    margin-top: 20px;
}
@media only screen and (min-width: 969px) {
    .gdpr-notification-accept-btn {
        margin-top: 0;
    }
}
.gdpr-notification-accept-btn:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.9);
}
.gdpr-notification-close-btn-wrapper {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -16px;
    right: 0;
    background-color: rgba(0, 37, 70, 0.92);
    border-radius: 50%;
}
.gdpr-notification-close-btn-wrapper:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(0 0 5px #fff);
            filter: drop-shadow(0 0 5px #fff);
}
.gdpr-notification-close-btn {
    width: 100%;
    height: 100%;
}

.loader-container {
    height: 400px;
    display: -webkit-flex;
    display: flex;
}

.loader {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 60px;
    height: 60px;
    margin: auto;
}

.loader__one,
.loader__two,
.loader__three,
.loader__four {
    width: 30px;
    -webkit-animation: loader 2s infinite;
            animation: loader 2s infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.loader__two {
    -webkit-animation-delay: 500ms;
            animation-delay: 500ms;
}

.loader__three {
    -webkit-animation-delay: 1500ms;
            animation-delay: 1500ms;
}

.loader__four {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

@-webkit-keyframes loader {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
}

@keyframes loader {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
}

.error-section {
    text-align: center;
    max-width: 1280px;
    width: 100%;
    margin: 30px auto;
    padding: 50px 0;
}

.error-section__title .section-title {
    font-size: 50px;
}

.error-section__text {
    font-size: 16px;
    margin-top: 56px;
}

.error-section__reload {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background-color: rgb(5, 47, 105);
    display: inline-block;
    color: white;
    padding: 18px 25px;
    border-radius: 2px;
    margin-top: 35px;
}

.error-section__reload:hover {
    cursor: pointer;
}

@media all and (max-width: 992px) {
    .error-section {
        margin: 20px auto;
        padding: 30px 0;
    }
    .error-section__title .section-title {
        font-size: 36px;
    }
    .error-section__text {
        margin-top: 36px;
    }

    .error-section__reload {
        margin-top: 25px;
    }
}

@media all and (max-width: 767px) {
    .error-section__title .section-title {
        font-size: 28px;
    }
    .error-section__text {
        font-size: 14px;
        margin-top: 26px;
    }

    .error-section__reload {
        font-size: 12px;
        margin-top: 25px;
    }
}

.page {
    margin-top: 100px;
}

@media all and (max-width: 767px) {
    .page {
        margin-top: 50px;
    }
}

.dark-blue {
    background-color: rgb(5, 47, 105);
}

.section-container.container-1280 {
    padding: 50px 52px 50px 52px;
    position: relative;
    width: 97%;
    margin: 0 auto;
}

@media all and (max-width: 992px) {
    .section-container.container-1280 {
        padding: 30px 25px 30px 25px;
        width: 100%;
    }
}

@media all and (max-width: 767px) {
    .section-container.container-1280 {
        padding: 20px 12px 20px 12px;
    }
}

.all-news {
    padding: 87px 0 105px 0;
    background-color: rgb(5, 47, 105);
}

.all-news .all-news-item {
    width: 46%;
    height: 230px;
    margin-top: 40px;
    overflow: hidden;
}

.all-news__content.container-1280 {
    width: 97%;
    margin: 54px auto 90px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 24px;
}

@media all and (max-width: 992px) {
    .all-news {
        padding: 74px 12px 65px 12px;
    }

    .all-news .all-news-item {
        width: 48%;
    }

    .all-news__content.container-1280 {
        margin: 35px auto 50px auto;
        padding: 0;
    }
}

@media all and (max-width: 767px) {
    .all-news {
        padding: 32px 12px 46px 12px;
    }

    .all-news .all-news-item {
        width: 100%;
        height: 80px;
        margin-top: 15px;
    }

    .all-news__content.all-news__content.container-1280 {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-top: 15px;
        margin-bottom: 50px;
        padding: 0;
    }
}

.not-found-page h4 {
    color: rgb(5, 47, 105);
    font-size: 200px;
    text-align: center;
    margin-bottom: 50px;
}

.not-found-page__title {
    color: rgb(5, 47, 105);
    font-size: 50px;
    text-align: center;
}

@media all and (max-width: 992px) {
    .not-found-page h4 {
        font-size: 100px;
        margin-bottom: 30px;
    }

    .not-found-page__title {
        font-size: 40px;
    }
}

@media all and (max-width: 767px) {
    .not-found-page h4 {
        font-size: 100px;
    }

    .not-found-page__title {
        font-size: 30px;
    }
}

/* .RC-page {
    margin-top: 210px;
} */

/* @media all and (max-width: 992px) {
    .RC-page {
        margin-top: 0;
    }
} */

@media all and (max-width: 767px) {
    .RC-page {
        margin-top: 75px;
    }
}

.dark-blue {
    background-color: rgb(5, 47, 105);
}

.rc-section-container.container-1280 {
    padding: 50px 52px 50px 52px;
    position: relative;
    width: 97%;
    margin: 0 auto;
}

@media all and (max-width: 992px) {
    .rc-section-container.container-1280 {
        padding: 30px 25px 30px 25px;
        width: 100%;
    }
}

@media all and (max-width: 767px) {
    .rc-section-container.container-1280 {
        padding: 20px 12px 20px 12px;
    }
}

.section-title-RC {
    color: rgb(5, 47, 105);
    font-size: 30px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
    /* margin-left: 32px; */
}

/* .section-title-RC:before {
    content: '';
    border: solid rgb(218, 0, 25);
    border-width: 3px 0 0 0;
    display: inline-block;
    width: 24px;
    transform: rotate(-70deg);
    -webkit-transform: rotate(-70deg);
    position: absolute;
    top: 16px;
    left: -26px;
}

.section-title-RC:after {
    content: '';
    border: solid rgb(218, 0, 25);
    border-width: 3px 0 0 0;
    display: inline-block;
    width: 24px;
    transform: rotate(-70deg);
    -webkit-transform: rotate(-70deg);
    position: absolute;
    top: 16px;
    left: -36px;
} */

.section-title-RC.dark-bg-title {
    color: rgb(255, 255, 255);
}

.section-title-RC.dark-bg-title:after,
.section-title-RC.dark-bg-title:before {
    border: solid rgb(5, 47, 105);
    border-width: 3px 0 0 0;
}

@media all and (max-width: 992px) {
    .section-title-RC {
        font-size: 24px;
    }

    .section-title-RC:before,
    .section-title-RC:after {
        width: 21px;
        top: 13px;
    }
}

@media all and (max-width: 767px) {
    .section-title-RC {
        font-size: 20px;
    }

    .section-title-RC:before,
    .section-title-RC:after {
        width: 19px;
        top: 10px;
    }
}

.rc-top-news-item {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
}

.rc-top-news__content__left .rc-top-news-item {
    height: 269px;
}

.rc-top-news__content__right .rc-top-news-item {
    height: 100%;
}

.rc-top-news__content__left .rc-top-news-item__content,
.rc-top-news__content__right .rc-top-news-item__content {
    height: 100%;
}

.rc-top-news-item:hover .rc-top-news-item__content {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

.rc-top-news-item__content {
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.rc-top-news-item__content:after {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.rc-top-news-item__title {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-top: -110px;
    padding: 0 20px 0 40px;
    position: relative;
    z-index: 2;
}

.rc-top-news-item__title h5 {
    font-size: 18px;
    line-height: 1.28;
    font-weight: 500;
    letter-spacing: -0.4px;
    z-index: 2;
    padding-top: 8px;
}

.rc-top-news-item__title h6 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: rgb(255, 255, 255);
    z-index: 2;
    position: relative;
    padding-bottom: 8px;
}

.rc-top-news-item__title h6:after {
    content: '';
    width: 60.5px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.rc-top-news-item a,
.rc-top-news-item a:visited {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

@media all and (max-width: 992px) {
    .rc-top-news-item:hover .rc-top-news-item__content {
        -webkit-transform: none;
                transform: none;
    }

    .rc-top-news__content__left .rc-top-news-item {
        height: 162px;
    }

    .rc-top-news-item__title {
        margin-top: -74px;
    }

    .rc-top-news-item__title h5 {
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: normal;
    }

    .rc-top-news-item__title h6 {
        font-size: 12px;
        letter-spacing: -0.3px;
        padding-bottom: 6px;
    }

    .rc-top-news-item__title h6:after {
        width: 36px;
    }
}

@media all and (max-width: 767px) {
    .rc-top-news__content__left .rc-top-news-item,
    .rc-top-news__content__right .rc-top-news-item {
        height: 220px;
    }

    .rc-top-news-item__title {
        padding: 0 20px;
        margin-top: -100px;
    }

    .rc-top-news-item__title h5 {
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
    }

    .rc-top-news-item__title h6 {
        font-size: 12px;
        letter-spacing: normal;
    }
}

.rc-top-news.container-1280 {
    width: 97%;
    padding: 30px 0 5px 0;
    position: relative;
    z-index: 1;
}

.rc-top-news__content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 30px;
}

.rc-top-news__content__left {
    width: 54%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.rc-top-news__content__left.rc-top-news__content__left--centered {
    margin: auto;
}

.rc-top-news__content__right {
    height: 548px;
    width: 45%;
}

@media all and (max-width: 992px) {
    .rc-top-news.container-1280 {
        padding: 20px 20px 5px 25px;
    }

    .rc-top-news__content {
        margin-top: 15px;
    }

    .rc-top-news__content__right {
        height: 336px;
    }
}

@media all and (max-width: 767px) {
    .rc-top-news.container-1280 {
        padding: 20px 12px 5px 12px;
        margin: 0 auto;
        width: 100%;
    }

    .rc-top-news__content {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-top: 15px;
    }

    .rc-top-news__content__left {
        width: 100%;
    }

    .rc-top-news__content__right {
        width: 100%;
        height: auto;
    }
}

.rc-all-news-item {
    width: 46%;
    height: 230px;
    margin-top: 40px;
    overflow: hidden;
}

.rc-all-news-item a {
    display: -webkit-flex;
    display: flex;
}

.rc-all-news-item__image {
    width: 230px;
    height: 230px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
}

.rc-all-news-item__content {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.rc-all-news-item__content h5 {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 11px;
}

.rc-all-news-item:hover h5 {
    color: rgb(224, 27, 34);
}

.rc-all-news-item__content h6 {
    font-size: 14px;
    color: rgb(51, 51, 51);
    position: relative;
    padding-bottom: 15px;
    letter-spacing: -0.4px;
}

.rc-all-news-item__content h6:after {
    content: '';
    width: 36px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.rc-all-news-item__content p {
    color: rgb(51, 51, 51);
    font-size: 16px;
    margin-top: 18px;
}

.rc-all-news-item__content p b {
    font-weight: lighter;
}

.rc-all-news-item a,
.rc-all-news-item a:visited {
    text-decoration: none;
    color: rgb(51, 51, 51);
}

@media all and (max-width: 992px) {
    .rc-all-news-item {
        width: 48%;
        height: 100px;
        margin-top: 17px;
    }

    .rc-all-news-item__image {
        width: 100px;
        height: 100px;
        margin-right: 14px;
    }

    .rc-all-news-item__content h5 {
        font-size: 16px;
        padding-bottom: 9px;
        margin-top: 0;
        line-height: 1.31;
    }

    .rc-all-news-item:hover h5 {
        color: rgb(51, 51, 51);
    }

    .rc-all-news-item__content h6 {
        padding-bottom: 0;
    }

    .rc-all-news-item__content h6:after {
        height: 0;
    }
}

@media all and (max-width: 767px) {
    .rc-all-news-item {
        width: 100%;
        height: 80px;
        margin-top: 15px;
    }

    .rc-all-news-item__image {
        width: 80px;
        height: 80px;
        margin-right: 10px;
    }

    .rc-all-news-item__content h5 {
        font-size: 14px;
        padding-bottom: 5px;
    }

    .rc-all-news-item__content h6 {
        font-size: 12px;
        letter-spacing: -0.3px;
    }

    .rc-all-news-item__content p {
        display: none;
    }
}

.rc-all-news {
    padding: 87px 0 105px 0;
    background-color: rgb(5, 47, 105);
}

.rc-all-news__content.container-1280 {
    width: 97%;
    margin: 54px auto 90px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 24px;
}

@media all and (max-width: 992px) {
    .rc-all-news {
        padding: 74px 12px 65px 12px;
    }

    .rc-all-news__content.container-1280 {
        margin: 35px auto 50px auto;
        padding: 0;
    }
}

@media all and (max-width: 767px) {
    .rc-all-news {
        padding: 32px 12px 46px 12px;
    }

    .rc-all-news__content.rc-all-news__content.container-1280 {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-top: 15px;
        margin-bottom: 50px;
        padding: 0;
    }
}

.not-found-page h4 {
    color: rgb(5, 47, 105);
    font-size: 200px;
    text-align: center;
    margin-bottom: 50px;
}

.not-found-page__title {
    color: rgb(5, 47, 105);
    font-size: 50px;
    text-align: center;
    margin-bottom: 50px;
}

@media all and (max-width: 992px) {
    .not-found-page h4 {
        font-size: 100px;
        margin-bottom: 30px;
    }

    .not-found-page__title {
        font-size: 40px;
    }
}

@media all and (max-width: 767px) {
    .not-found-page h4 {
        font-size: 100px;
    }

    .not-found-page__title {
        font-size: 30px;
    }
}

.additional-info {
    margin-bottom: 28px;
}

.additional-info a {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
    padding-right: 11px;
    text-underline-position: under;
    margin-right: 11px;
    color: rgb(255, 255, 255);
    line-height: 1.4;
    border-right: solid 1px rgb(255, 255, 255);
}

.additional-info a:last-of-type {
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
}

@media all and (max-width: 992px) {
    .additional-info {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .additional-info a {
        border-right: none;
    }

    .additional-info a:nth-of-type(1) {
        margin-bottom: 16px;
    }
}

@media all and (max-width: 767px) {
    .additional-info {
        margin-bottom: 0;
    }

    .additional-info a {
        font-size: 12px;
        border-right: none;
    }

    .additional-info a:nth-of-type(1) {
        margin-bottom: 14px;
    }
}

.page-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 300px;
    padding: 30px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgb(5, 47, 105);
}

.page-info.page-info--gray {
    background-color: rgb(51, 51, 51);
    background-image: none;
}

.page-info .container-1280 {
    width: 97%;
    height: 100%;
}

.page-info.page-info--overlay {
    position: relative;
}

.page-info.page-info--overlay:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(5, 47, 105, 0.8);
}

.page-info.page-info--overlay .page-info__breadcrumbs {
    position: relative;
    z-index: 1;
}

.page-info.page-info--overlay .page-info__content {
    position: relative;
    z-index: 1;
}

.page-info__breadcrumbs {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    height: 16px;
    margin-left: -6px;
}

.page-info__breadcrumbs__item {
    font-size: 14px;
    height: 16px;
    margin: 0 14px;
    position: relative;
}

.page-info__breadcrumbs__item:nth-of-type(1) {
    margin-left: 10px;
}

.page-info__breadcrumbs__item:after {
    content: '';
    border: solid rgb(224, 27, 34);
    border-width: 0 2px 2px 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    top: 4px;
    right: -17px;
}

.page-info__breadcrumbs__item:nth-last-of-type(1):after {
    border: none;
}

.page-info__breadcrumbs__item a {
    color: rgb(255, 255, 255);
    font-size: 14px;
    height: 16px;
    text-decoration: none;
    font-weight: 500;
}

.page-info__breadcrumbs__item:last-of-type a {
    font-weight: normal;
}

.page-info__breadcrumbs__item:nth-last-of-type(1) a:hover,
.page-info__breadcrumbs__item:nth-last-of-type(1):hover {
    cursor: context-menu;
}

.page-info__content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 0 0 0 100px;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
}

.page-info__content__title .section-title {
    text-transform: uppercase;
    font-weight: 500;
}

.page-info__content__image {
    width: 45%;
}

.page-info__content__image img {
    width: 75%;
}

.page-info.page-info--gray .page-info__content__image {
    width: 150px;
}

@media all and (max-width: 992px) {
    .page-info {
        height: 250px;
    }

    .page-info__breadcrumbs {
        display: none;
    }

    .page-info__content {
        padding: 0 0 0 25px;
    }

    .page-info__content__image {
        width: 35%;
    }
}

@media all and (max-width: 767px) {
    .page-info {
        height: auto;
    }
}

.top-news-item {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
}

.top-news__content__left .top-news-item {
    height: 269px;
}

.top-news__content__right .top-news-item {
    height: 100%;
}

.top-news__content__left .top-news-item__content,
.top-news__content__right .top-news-item__content {
    height: 100%;
}

.top-news-item:hover .top-news-item__content {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

.top-news-item__content {
    background-size: cover;
    background-position: top;
    position: relative;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.top-news-item__content:after {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.top-news-item__title {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-top: -110px;
    padding: 0 20px 0 40px;
    position: relative;
    z-index: 2;
}

.top-news-item__title h5 {
    font-size: 18px;
    line-height: 1.28;
    font-weight: 500;
    letter-spacing: -0.4px;
    z-index: 2;
    padding-top: 8px;
}

.top-news-item__title h6 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: rgb(255, 255, 255);
    z-index: 2;
    position: relative;
    padding-bottom: 8px;
}

.top-news-item__title h6:after {
    content: '';
    width: 60.5px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.top-news-item a,
.top-news-item a:visited {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

@media all and (max-width: 992px) {
    .top-news-item:hover .top-news-item__content {
        -webkit-transform: none;
                transform: none;
    }

    .top-news__content__left .top-news-item {
        height: 162px;
    }

    .top-news-item__title {
        margin-top: -74px;
    }

    .top-news-item__title h5 {
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: normal;
    }

    .top-news-item__title h6 {
        font-size: 12px;
        letter-spacing: -0.3px;
        padding-bottom: 6px;
    }

    .top-news-item__title h6:after {
        width: 36px;
    }
}

@media all and (max-width: 767px) {
    .top-news__content__left .top-news-item,
    .top-news__content__right .top-news-item {
        height: 220px;
    }

    .top-news-item__title {
        padding: 0 20px;
        margin-top: -100px;
    }

    .top-news-item__title h5 {
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
    }

    .top-news-item__title h6 {
        font-size: 12px;
        letter-spacing: normal;
    }
}

.top-news.container-1280 {
    width: 97%;
    padding: 30px 0 5px 0;
    position: relative;
    z-index: 1;
}

.top-news__content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 30px;
}

.top-news__content__left {
    width: 54%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.top-news__content__left.top-news__content__left--centered {
    margin: auto;
}

.top-news__content__right {
    height: 548px;
    width: 45%;
}

@media all and (max-width: 992px) {
    .top-news.container-1280 {
        padding: 20px 20px 5px 25px;
    }

    .top-news__content {
        margin-top: 15px;
    }

    .top-news__content__right {
        height: 336px;
    }
}

@media all and (max-width: 767px) {
    .top-news.container-1280 {
        padding: 20px 12px 5px 12px;
        margin: 0 auto;
        width: 100%;
    }

    .top-news__content {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-top: 15px;
    }

    .top-news__content__left {
        width: 100%;
    }

    .top-news__content__right {
        width: 100%;
        height: auto;
    }
}

.events-item {
    width: 380px;
    height: 240px;
    border: solid 1px rgb(221, 221, 221);
    background-color: rgb(255, 255, 255);
    margin-bottom: 20px;
    margin-right: 15px;
    box-shadow: 0 22px 14px -15px rgba(0, 0, 0, 0.15);
}

.events-item:nth-of-type(3n) {
    margin-right: 0;
}

.events-item:hover {
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.events-item a {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 35px 18px;
}

.events-item__image {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
}

.events-section__content.events-section_content--center .events-item {
    margin-right: 16px;
}

.events-item__content {
    width: 100%;
}

.events-item__content.events-item__content--width-image {
    width: 75%;
}

.events-item__content__info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.events-item__content__info:after {
    content: '';
    width: 39px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    bottom: -12px;
    left: 0;
}

.events-item__content__info h6 {
    color: rgb(51, 51, 51);
    font-size: 15px;
}

.events-item__content__info__category {
    padding: 5px 20px;
    border-radius: 30px;
    display: -webkit-flex;
    display: flex;
    text-align: center;
}

.events-item__content__info__category div {
    margin: auto;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 13px;
}

.events-item h5 {
    position: relative;
    font-size: 17px;
    margin-top: 20px;
    line-height: 1.29;
}

.events-item p {
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.43;
    color: rgb(51, 51, 51);
}

.events-item a,
.events-item a:visited {
    text-decoration: none;
    color: rgb(5, 47, 105);
}

.events__content p b {
    font-weight: lighter;
}

@media all and (max-width: 1332px) {
    .events-item {
        width: 340px;
        margin-right: 15px;
    }
}

@media all and (max-width: 1332px) {
    .events-item {
        /* flex-basis: calc((100% - 30px) / 3); */
        /* width: 300px; */
        margin-right: 12px;
    }

    .events-item:nth-of-type(3n) {
        margin-right: 15px;
    }
}

@media all and (max-width: 992px) {
    .events-item {
        -webkit-flex-basis: calc(50% - 7.5px);
                flex-basis: calc(50% - 7.5px);
    }

    .events-item:nth-of-type(3n) {
        margin-right: 15px;
    }

    .events-item:nth-of-type(2n) {
        margin-right: 0;
    }
}

@media all and (max-width: 767px) {
    .events-item {
        width: 100%;
        min-width: 100%;
        height: 100%;
        background-color: rgb(247, 247, 247);
        margin-bottom: 0;
        margin-right: 0;
        border: none;
        border-bottom: solid 2px rgba(181, 181, 181, 0.6);
        background-image: none;
        box-shadow: none;
    }

    .events-item--dark.events-item {
        background-color: transparent;
    }

    .events-item:hover {
        box-shadow: none;
        -webkit-transform: none;
                transform: none;
    }

    .events-item a {
        padding: 17px 0;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
    }

    .events-item__content {
        margin-right: 16px;
    }

    .events-item__content__info {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .events-item__content__info:after {
        bottom: -2px;
    }

    .events-item__content__info h6 {
        font-size: 14px;
        color: rgb(119, 119, 119);
        margin-right: 30px;
    }

    .events-item--dark .events-item__content__info h6 {
        color: rgb(255, 255, 255);
        font-weight: 500;
    }

    .events-item h5 {
        font-size: 16px;
        padding-bottom: 0;
        margin-top: 13px;
    }

    .events-item--dark.events-item a,
    .events-item--dark.events-item a:visited {
        color: rgb(255, 255, 255);
    }

    .events-item--dark.events-item h5 {
        font-weight: 500;
    }

    .events-item p {
        display: none;
    }
}

.events-list {
    margin-top: 50px;
}

.events-list__content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    /* margin-top: 70.5px; */
    margin-bottom: 44px;
}

.events-list__content.events-list_content--center {
    -webkit-justify-content: center;
            justify-content: center;
}

@media all and (max-width: 992px) {
    .events-list {
        margin-top: 30px;
    }
}

@media all and (max-width: 767px) {
    .events-list {
        margin-top: 20px;
    }
}

.details-link {
    text-align: right;
}

.details-link__text {
    margin: 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
    color: rgb(5, 47, 105);
    position: relative;
    display: inline-block;
    padding: 0 1px;
    transition: color ease 0.3s;
}

.details-link__text.details-link__text--white {
    color: rgb(255, 255, 255);
}

.details-link__text::before,
.details-link__text::after {
    content: '';
    position: absolute;
    bottom: 0px;
    background-color: rgb(5, 47, 105);
    z-index: 1;
    height: 2px;
}

.details-link__text::before {
    width: 0%;
    left: 0;
    bottom: -2px;
    transition: width ease 0.4s;
}

.details-link__text::after {
    width: 100%;
    left: 0;
    bottom: -2px;
    transition: all ease 0.6s;
}

.details-link__text.details-link__text--white::before,
.details-link__text.details-link__text--white::after {
    background-color: rgb(255, 255, 255);
}

.details-link__text:hover::before {
    width: 100%;
}

.details-link__text:hover::after {
    left: 100%;
    width: 0%;
    transition: all ease 0.2s;
}

@media all and (max-width: 992px) {
    .details-link {
        text-align: center;
    }

    .details-link__text {
        font-size: 14px;
        letter-spacing: 1.3px;
    }

    .details-link__text::before,
    .details-link__text::after {
        z-index: 1;
    }
}

.events-list__no-events {
    margin-top: 70px;
}

.events-list__no-events img {
    display: block;
    margin: 0 auto;
    margin-bottom: 32px;
    width: 105px;
}

.events-list__no-events__msg {
    text-align: center;
    font-size: 22px;
    line-height: 1.41;
    color: rgb(181, 181, 181);
}

.events-list__no-events--dark .events-list__no-events__msg {
    color: rgb(51, 51, 51);
}

.events-list__no-events__form {
    display: -webkit-flex;
    display: flex;
    width: 565px;
    margin: 40px auto 0 auto;
}

.events-list__no-events__form input {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 16px;
    padding-left: 30px;
    border-radius: 2px;
    border: solid 1px rgb(181, 181, 181);
    color: rgb(181, 181, 181);
}

.events-list__no-events--dark .events-list__no-events__form input {
    border: solid 1px rgb(105, 105, 105);
}

.events-list__no-events__form input::-webkit-input-placeholder {
    color: rgb(181, 181, 181);
    font-style: italic;
}

.events-list__no-events__form input::placeholder {
    color: rgb(181, 181, 181);
    font-style: italic;
}

.events-list__no-events--dark .events-list__no-events__form input::-webkit-input-placeholder {
    color: rgb(105, 105, 105);
}

.events-list__no-events--dark .events-list__no-events__form input::placeholder {
    color: rgb(105, 105, 105);
}

.events-list__no-events__form__button {
    border-radius: 2px;
    border: solid 2px rgb(255, 255, 255);
    background-color: rgb(5, 47, 105);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 60px;
    margin-left: 10px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    position: relative;
}

.events-list__no-events__form__button:hover {
    cursor: pointer;
}

.events-list__no-events__form__button:hover:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.15);
}

@media all and (max-width: 992px) {
    .events-list__no-events {
        margin-top: 40px;
    }

    .events-list__no-events img {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        margin-bottom: 10px;
    }

    .events-list__no-events__msg {
        font-size: 16px;
    }

    .events-list__no-events__form {
        width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        max-width: 403px;
        margin: 20px auto 0 auto;
    }

    .events-list__no-events__form input {
        height: 60px;
        padding: 5px 0 5px 10px;
    }

    .events-list__no-events__form__button {
        margin: 10px auto 0 auto;
        letter-spacing: 1px;
        line-height: 35px;
    }
}

@media all and (max-width: 767px) {
    .events-list__no-events__form__button {
        font-size: 14px;
    }
}

.general-news .events-section .details-link {
    display: none;
}

.category-news .general-news__events-section {
    display: none;
}

.data-protection p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.44;
    text-align: justify;
}

.data-protection h3 {
    color: rgb(5, 47, 105);
    font-size: 26px;
    margin: 40px auto;
    text-align: center;
}

.data-protection ul {
    list-style-type: none;
    margin-bottom: 20px;
}

.data-protection li {
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.44;
    text-align: justify;
}

/* .data-protection  ol,
.data-protection  ul {
    margin-bottom: 20px;
    list-style-type: none;
}

.data-protection  li {
    margin-left: 30px;
    margin-top: 10px;
    position: relative;
    font-size: 18px;
    line-height: 1.44;
}

.data-protection  ol li {
    counter-increment: list;
    list-style-type: none;
    position: relative;
}

.data-protection  ol li:before {
    color: rgb(224, 27, 34);
    content: counter(list) '.';
    left: -32px;
    position: absolute;
    text-align: right;
    width: 26px;
}

.data-protection  ul li:before {
    content: '•';
    color: rgb(224, 27, 34);
    position: absolute;
    left: -15px;
    top: 2px;
} */

@media all and (max-width: 992px) {
    .data-protection p {
        margin-bottom: 15px;
        font-size: 16px;
    }

    .data-protection h3 {
        font-size: 22px;
    }

    .data-protection li {
        font-size: 16px;
    }
}

@media all and (max-width: 767px) {
    .data-protection p {
        font-size: 14px;
        text-align: left;
    }

    .data-protection li {
        font-size: 14px;
    }

    .data-protection h3 {
        color: rgb(5, 47, 105);
        font-size: 18px;
        margin: 40px auto;
        text-align: center;
    }
}

.rc-page-info {
    height: 300px;
    padding: 30px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgb(247, 247, 247);
}

.rc-page-info .section-title-RC {
    font-size: 40px;
    text-align: center;
}

.rc-page-info .container-1280 {
    width: 97%;
    height: 100%;
}

.rc-page-info.rc-page-info--overlay {
    position: relative;
}

.rc-page-info.rc-page-info--overlay:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(5, 47, 105, 0.6);
}

.rc-page-info.rc-page-info--overlay .rc-page-info__content {
    position: relative;
    z-index: 1;
}

.rc-page-info__content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 0 0 100px;
    height: 100%;
    align-items: center;
}

@media all and (max-width: 992px) {
    .rc-page-info {
        height: 250px;
    }

    .rc-page-info__content {
        padding: 0 0 0 25px;
    }

    .rc-page-info .section-title-RC {
        font-size: 30px;
    }
}

@media all and (max-width: 767px) {
    .rc-page-info {
        height: auto;
    }

    .rc-page-info .section-title-RC {
        font-size: 20px;
    }
}

.single-activity-list-item-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
@media all and (min-width: 720px) {
    .single-activity-list-item-container {
        width: 48%;
    }
}

@media all and (min-width: 1440px) {
    .single-activity-list-item-container {
        width: 32%;
    }
}

.single-activity-list-item-container:hover .single-activity-title-card-wrapper {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
}

.single-activity-list-item-wrapper {
    width: 314px;
    height: 415px;
    position: relative;
}
@media all and (min-width: 920px) {
    .single-activity-list-item-wrapper {
        width: 416px;
        height: 375px;
    }
}

.single-activity-featured-image {
    width: 100%;
    height: 364px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}
@media all and (min-width: 868px) {
    .single-activity-featured-image {
        height: 328px;
    }
}

.single-activity-title-card-wrapper {
    width: 287px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fafafa;
    padding: 20px;
}
@media all and (min-width: 868px) {
    .single-activity-title-card-wrapper {
        width: 318px;
        padding: 20px 30px 25px 25px;
    }
}

.single-activity-title-card-title {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 39px;
}

.single-activity-title-card-date-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.single-activity-title-card-date-horizontal-line {
    width: 25px;
    height: 1px;
    background-color: #000000;
    margin-right: 21px;
}

.single-activity-title-card-date {
    color: #000000;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 0;
}

.dubai-expo-2020-top-banner {
    margin-top: 50px;
    width: 100%;
    height: 142.85714285714286vw;
    background-image: url('/images/expo-dubai-header-700x1000.jpg');
    /* background-image: url('/images/expo-dubai-header-700x1000-02.jpg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 32px;
    position: relative;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-top-banner {
        margin-top: 100px;
        height: 26.041666666666668vw;
        background-image: url('/images/expo-dubai-header-1920x500.jpg');
        /* background-image: url('/images/expo-dubai-header-1920x500-02.jpg'); */
    }
}

.dubai-expo-2020-section-container {
    width: 100%;
    padding: 32px 32px 42px 32px;
}
.dubai-expo-2020-title {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 20px;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-title {
        max-width: 899px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
@media all and (min-width: 768px) {
    h2 {
        font-size: 37px;
    }
}

.dubai-expo-2020-section-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 42px;
}

.dubai-expo-2020-subtitle {
    color: #000000;
    font-family: 'Playfair Display';
    font-size: 18px;
    text-align: center;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 24px;
}

@media all and (min-width: 768px) {
    .dubai-expo-2020-subtitle {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

.dubai-expo-2020-regular-txt {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.58;
    margin-bottom: 40px;
    text-align: justify;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-regular-txt {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 26px;
    }
}

.dubai-expo-2020-inner-html-content {
    padding-top: 42px;
    padding-bottom: 42px;
}
.dubai-expo-2020-inner-html-content p {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.58;
    margin-bottom: 40px;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-inner-html-content p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.58;
        margin-bottom: 40px;
    }
}

.dubai-expo-2020-inner-html-content > h1,
.dubai-expo-2020-inner-html-content > h2,
.dubai-expo-2020-inner-html-content > h3,
.dubai-expo-2020-inner-html-content > h4,
.dubai-expo-2020-inner-html-content > h5,
.dubai-expo-2020-inner-html-content > h6 {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 18px;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: 32px;
    padding-left: 6vw;
    padding-right: 6vw;
    max-width: 899px;
}

@media all and (min-width: 768px) {
    .dubai-expo-2020-inner-html-content > h1,
    .dubai-expo-2020-inner-html-content > h2,
    .dubai-expo-2020-inner-html-content > h3,
    .dubai-expo-2020-inner-html-content > h4,
    .dubai-expo-2020-inner-html-content > h5,
    .dubai-expo-2020-inner-html-content > h6 {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

.dubai-expo-2020-publish-date-card {
    background-color: #fafafa;
    color: #063779;
    font-family: 'Source Sans Pro';
    position: absolute;
    bottom: -1vw;
    left: 0;
    padding: 2vw 4vw;
    font-size: 12px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-publish-date-card {
        font-size: 14px;
    }
}

.dubai-expo-2020-document-wrapper {
    width: 360px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 24px;
}

.dubai-expo-2020-single-document-title {
    color: #939393;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-left: 16px;
}

.dubai-expo-2020-document-wrapper:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.dubai-expo-aktivnost-headline-image-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.dubai-expo-aktivnost-headline-image {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .dubai-expo-aktivnost-headline-image {
        width: 899px;
        margin: 0 auto;
    }
}

.center-txt {
    text-align: center;
}
.dubai-section-title-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.dubai-expo-subtitle {
    margin: 24px 0;
    font-size: 20px;
    font-weight: 300;
}

@media all and (min-width: 993px) {
    .dubai-expo-subtitle {
        font-size: 30px;
    }
}

.dubaiexpo-mrgtop {
    margin-top: 24px;
}

.single-activity-list-item-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
@media all and (min-width: 720px) {
    .single-activity-list-item-container {
        width: 48%;
    }
}

@media all and (min-width: 1440px) {
    .single-activity-list-item-container {
        width: 32%;
    }
}

.single-activity-list-item-container:hover .single-activity-title-card-wrapper {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
}

.single-activity-list-item-wrapper {
    width: 314px;
    min-height: 440px;
    position: relative;
}
@media all and (min-width: 920px) {
    .single-activity-list-item-wrapper {
        width: 416px;
        min-height: 460px;
    }
}

.single-activity-featured-image {
    width: 100%;
    height: 364px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}
@media all and (min-width: 868px) {
    .single-activity-featured-image {
        height: 328px;
    }
}

.single-activity-title-card-wrapper {
    width: 287px;
    position: absolute;
    top: 280px;
    left: 0;
    background-color: #fafafa;
    padding: 20px;
}
@media all and (min-width: 868px) {
    .single-activity-title-card-wrapper {
        width: 318px;
        padding: 20px 30px 25px 25px;
    }
}

.single-activity-title-card-title {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 39px;
}

.single-activity-title-card-date-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    bottom: 20px;
}

.single-activity-title-card-date-horizontal-line {
    width: 25px;
    height: 1px;
    background-color: #000000;
    margin-right: 21px;
}

.single-activity-title-card-date {
    color: #000000;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 0;
}

.open-balkan-top-banner {
    margin-top: 50px;
    width: 100%;
    height: 142.85714285714286vw;
    background-image: url('/images/open-balkan-mobile-visual.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 32px;
    position: relative;
}
@media all and (min-width: 768px) {
    .open-balkan-top-banner {
        margin-top: 100px;
        height: 26.041666666666668vw;
        background-image: url('/images/open-balkan-page-topbanner.png');
    }
}

.open-balkan-section-container {
    width: 100%;
    padding: 32px 32px 42px 32px;
}
.open-balkan-title {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 20px;
}
@media all and (min-width: 768px) {
    .open-balkan-title {
        max-width: 899px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
@media all and (min-width: 768px) {
    h2 {
        font-size: 37px;
    }
}

.open-balkan-section-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-top: 42px;
    margin-bottom: 42px;
}

.open-balkan-subtitle {
    color: #000000;
    font-size: 18px;
    text-align: center;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 24px;
}

@media all and (min-width: 768px) {
    .open-balkan-subtitle {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

.open-balkan-regular-txt {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 40px;
    text-align: justify;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .open-balkan-regular-txt {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 26px;
    }
}

.open-balkan-inner-html-content {
    padding-top: 42px;
    padding-bottom: 42px;
}
.open-balkan-inner-html-content p {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.58;
    margin-bottom: 40px;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .open-balkan-inner-html-content p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.58;
        margin-bottom: 40px;
    }
}

.open-balkan-inner-html-content > h1,
.open-balkan-inner-html-content > h2,
.open-balkan-inner-html-content > h3,
.open-balkan-inner-html-content > h4,
.open-balkan-inner-html-content > h5,
.open-balkan-inner-html-content > h6 {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 18px;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: 32px;
    padding-left: 6vw;
    padding-right: 6vw;
    max-width: 899px;
}
.open-balkan-inner-html-content > ul > li {
    margin-bottom: 20px;
    margin-left: 32px;
    font-size: 20px;
}

@media all and (min-width: 768px) {
    .open-balkan-inner-html-content > h1,
    .open-balkan-inner-html-content > h2,
    .open-balkan-inner-html-content > h3,
    .open-balkan-inner-html-content > h4,
    .open-balkan-inner-html-content > h5,
    .open-balkan-inner-html-content > h6 {
        font-size: 20px;
        margin-bottom: 32px;
    }
}
.open-balkan-inner-html-content p {
    font-size: 16px;
    line-height: 23px;
}
@media all and (min-width: 768px) {
    .open-balkan-inner-html-content p {
        font-size: 18px;
        line-height: 27px;
    }
}

.open-balkan-publish-date-card {
    background-color: #fafafa;
    color: #063779;
    font-family: 'Source Sans Pro';
    position: absolute;
    bottom: -1vw;
    left: 0;
    padding: 2vw 4vw;
    font-size: 12px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .open-balkan-publish-date-card {
        font-size: 14px;
    }
}

.open-balkan-document-wrapper {
    width: 360px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 24px;
}

.open-balkan-single-document-title {
    color: #939393;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-left: 16px;
}

.open-balkan-document-wrapper:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.open-balkan-aktivnost-headline-image-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.open-balkan-aktivnost-headline-image {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .open-balkan-aktivnost-headline-image {
        width: 899px;
        margin: 0 auto;
    }
}

.center-txt {
    text-align: center;
}
.dubai-section-title-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.open-balkan-subtitle {
    margin: 24px 0;
    font-size: 20px;
    font-weight: 300;
}

@media all and (min-width: 993px) {
    .open-balkan-subtitle {
        font-size: 30px;
    }
}

.open-balkan-mrgtop {
    margin-top: 24px;
}

.open-balkan-paggination-wrapper {
    width: 100%;
    margin-top: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    color: #909090;
}

.open-balkan-paggination-wrapper > svg > polygon {
    fill: #909090;
}
.open-balkan-pagination-numbers-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.open-balkan-pagination-number {
    margin: 0;
    line-height: 30px;
    width: 30px;
    margin-left: 7px;
    margin-right: 7px;
    text-align: center;
}
.open-balkan-pagination-number:hover {
    cursor: pointer;
    color: #063779;
}
.open-balkan-selected-pagination-number {
    color: #ffffff;
    background-color: #063779;
    border-radius: 50%;
}
.open-balkan-pagination-arrows-hover-effect:hover {
    cursor: pointer;
}
.open-balkan-pagination-arrows-hover-effect:hover > svg > polygon {
    fill: #063779;
}
.open-balkan-pagination-arrow-hide-it {
    opacity: 0;
}
.open-balkan-sporazumi-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #e7e7e7;
    color: rgb(6, 55, 122);
}
@media all and (min-width: 768px) {
    .open-balkan-sporazumi-wrapper {
        width: 48%;
        font-size: 20px;
    }
}
.open-balkan-sporazumi-wrapper:hover {
    cursor: pointer;
    color: #e01b22;
}

.open-balkan-topmargin {
    margin-top: 50px;
}
@media (min-width: 768px) {
    .open-balkan-topmargin {
        margin-top: 100px;
    }
}

.open-balkan-ucesnici-container {
    width: 100%;
    height: 256.8vw;
    background-image: url('/images/open-balkan-ucesnici-mob.jpg');
    background-size: 100% 100%;
}
@media all and (min-width: 1100px) {
    .open-balkan-ucesnici-container {
        height: 56.25vw;
        background-image: url('/images/open-balkan-ucesnici.jpg');
    }
}
.open-balkan-ucesnici-flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 200px;
}
@media all and (min-width: 1100px) {
    .open-balkan-ucesnici-flex-container {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

.open-balkan-single-ucesnik-wrapper {
    width: 314px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
    padding-top: 18px;
    padding-left: 29px;
    padding-right: 31px;
    padding-bottom: 20px;
}
@media all and (min-width: 1100px) {
    .open-balkan-single-ucesnik-wrapper {
        width: 420px;
        margin-bottom: 0;
        margin-left: 9px;
        margin-right: 9px;
    }
}
.open-balkan-ucesnik-name {
    color: #ffffff;
    font-family: 'Playfair Display';
    font-size: 23px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 0;
    opacity: 0.9;
}

.open-balkan-ucesnik-horizontal-line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.24);
    margin-top: 17px;
    margin-bottom: 17px;
}
.open-balkan-ucesnik-txt {
    color: #ffffff;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 5px;
    opacity: 0.79;
}



.RC-hamburger-navigation-list {
    display: none;
}

@media all and (max-width: 1024px) {
    .RC-hamburger-navigation-list {
        -webkit-align-items: center;
                align-items: center;
        background-color: rgb(5, 47, 105);
        border-top: solid 1px rgb(255, 255, 255);
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 100vh;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding-bottom: 13px;
        position: absolute;
        right: 0;
        top: 100%;
        width: 320px;
        z-index: 2;
    }

    .RC-hamburger-navigation-list__chamber-title {
        color: rgb(255, 255, 255);
        font-size: 17px;
        font-weight: normal;
        letter-spacing: -1px;
        line-height: 20px;
        padding: 50px 50px 10px 50px;
        text-align: center;
    }

    .RC-hamburger-navigation-list__nav {
        margin-top: 15px;
        list-style-type: none;
        width: 100%;
        margin-bottom: 25px;
    }

    .RC-hamburger-navigation-list__nav li {
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center;
        border-bottom: solid 1px rgba(233, 153, 155, 0.6);
    }

    .RC-hamburger-navigation-list__nav li a {
        color: rgb(255, 255, 255);
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .RC-hamburger-navigation-list__nav .is-active {
        color: rgb(224, 27, 34);
    }

    .RC-hamburger-navigation-list__options {
        display: -webkit-flex;
        display: flex;
        list-style-type: none;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 65px;
        cursor: pointer;
    }

    .RC-hamburger-navigation-list__options li {
        font-size: 16px;
        color: rgb(255, 255, 255);
        text-align: center;
        font-weight: normal;
        letter-spacing: -1px;
    }

    .RC-hamburger-navigation-list__options li.active-language {
        color: rgb(224, 27, 34);
    }

    .RC-hamburger-navigation-list__options li:nth-of-type(1) {
        border-right: 1px solid rgb(203, 203, 203);
        margin-right: 24px;
        padding-right: 24px;
    }

    .RC-hamburger-navigation-list__call-center {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -1px;
    }
}

@media all and (max-width: 767px) {
    .RC-hamburger-navigation-list {
        width: 100%;
        z-index: 2;
    }

    .RC-hamburger-navigation-list__chamber-title {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        padding: 12px 50px;
    }

    .RC-hamburger-navigation-list__nav {
        margin-top: 0;
    }

    .RC-hamburger-navigation-list__nav li {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .RC-hamburger-navigation-list__options {
        margin-top: 46px;
        margin-bottom: 72px;
    }

    .RC-hamburger-navigation-list__call-center {
        letter-spacing: 0;
    }
}

.RC-header {
    width: 100%;
    background-color: rgb(5, 47, 105);
    height: 210px;
}

.RC-header .container-1280 {
    height: 100%;
    width: 97%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 50px 0 0 0;
    color: rgb(255, 255, 255);
}

.RC-header__logo {
    width: 16%;
    margin-top: -5px;
}

.RC-header__logo img {
    width: 100%;
}

.RC-header__content {
    width: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0 40px;
}

.RC-header__content__chamber-name {
    text-decoration: none;
    color: white;
}

.RC-header__content__chamber-name:hover {
    color: rgb(224, 27, 34);
}

.RC-header__content__chamber-name h1 {
    font-size: 52px;
    line-height: 1;
}

.RC-header__content__chamber-name h3 {
    font-size: 21px;
    margin-top: 5px;
    font-weight: normal;
}

.RC-header__content__navigation {
    display: -webkit-flex;
    display: flex;
    list-style-type: none;
    margin-bottom: 15px;
}

.RC-header__content__navigation a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 7px;
}

.RC-header__content__navigation > li {
    margin-right: 40px;
}

.RC-header__content__navigation li:last-of-type {
    margin-right: 0;
    margin-left: auto;
}

.RC-header__content__navigation a:hover,
.RC-header__content__navigation a.is-active-link {
    border-bottom: 1px solid rgb(255, 255, 255);
}

.RC-header__content__language {
    display: -webkit-flex;
    display: flex;
    list-style-type: none;
    height: 100%;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.RC-header__content__language li {
    height: 20px;
    font-size: 15px;
}

.RC-header__content__language li:first-of-type {
    margin-right: 15px;
}

.RC-header__content__language li:nth-of-type(1) {
    border-right: 1px solid rgb(255, 255, 255);
    padding-right: 15px;
}

.RC-header__content__language li:hover {
    cursor: pointer;
}

.RC-header__content__language li.active-language {
    color: rgb(224, 27, 34);
}

.RC-header__image {
    height: 140px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
}

.RC-header__image img {
    height: 100%;
}

.RC-header__menu {
    display: none;
}

.RC-header__logo--mobile {
    display: none;
}

@media all and (max-width: 1248px) {
    .RC-header__content__navigation > li {
        margin-right: 20px;
    }

    .RC-header__content__navigation a {
        font-size: 14px;
    }

    .RC-header__image {
        height: 120px;
    }
}

@media all and (max-width: 1100px) {
    .RC-header__content__navigation > li {
        margin-right: 12px;
    }
}

@media all and (max-width: 1040px) {
    .RC-header__content h1 {
        font-size: 42px;
    }

    .RC-header__content h3 {
        font-size: 16px;
    }

    .RC-header__content__navigation li {
        margin-right: 25px;
    }
}

@media all and (max-width: 1024px) {
    .RC-header {
        position: relative;
    }

    .RC-header .container-1280 {
        -webkit-align-items: center;
                align-items: center;
        padding: 0;
    }

    .RC-header__logo {
        margin-bottom: 32px;
    }

    .RC-header__content__chamber-name:hover {
        color: white;
    }

    .RC-header__content {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        margin: 0 20px;
    }

    .RC-header__content > div {
        display: -webkit-flex;
        display: flex;
    }

    .RC-header__content h1 {
        font-size: 42px;
        letter-spacing: -4px;
        line-height: 70px;
        /* margin: 40px auto; */
        margin: 0;
    }

    .RC-header__content h3 {
        display: none;
    }

    .RC-header__content__navigation {
        display: none;
    }

    .RC-header__image {
        height: 127px;
    }

    .RC-header__menu {
        -webkit-align-self: center;
                align-self: center;
        display: block;
        margin-left: 20px;
    }

    .RC-header__menu__hamburger {
        height: 17px;
        width: 28px;
    }

    .RC-header__menu--close .RC-header__menu__close-menu {
        display: none;
    }

    .RC-header__menu--open .RC-header__menu__close-menu {
        display: inline;
    }

    .RC-header__menu--open .RC-header__menu__hamburger {
        display: none;
    }
}

@media all and (max-width: 767px) {
    .RC-header {
        position: fixed;
        z-index: 2;
        height: 75px;
    }

    .RC-header .container-1280 {
        /* align-items: center; */
        /* padding: 15px 15px 0 15px; */
    }

    .RC-header__logo {
        width: 40px;
        margin: 0 0 15px 0;
    }

    .RC-header__logo--large {
        display: none;
    }

    .RC-header__logo--mobile {
        display: inline-block;
        width: 100%;
    }

    .RC-header__content h1 {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 21px;
        /* margin: 0 0 15px 20px; */
        padding: 0;
    }

    .RC-header__image {
        height: 50px;
        /* align-self: flex-end; */
    }

    .RC-header__menu {
        margin-bottom: 15px;
    }
}

.RC-link-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    list-style-type: none;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    height: 130px;
}

.RC-link-list li {
    margin-bottom: 10px;
    width: 25%;
}

.RC-link-list li a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
}

.RC-link-list li a:hover {
    border-bottom: 1px solid rgb(255, 255, 255);
    padding-bottom: 3px;
}

@media all and (max-width: 992px) {
    .RC-link-list li a {
        font-size: 14px;
    }

    .RC-link-list li a:hover {
        border-bottom: none;
        padding-bottom: 0;
    }
}

@media all and (max-width: 767px) {
    .RC-link-list li a {
        font-size: 12px;
    }

    .RC-link-list {
        -webkit-flex-flow: column wrap;
                flex-flow: column wrap;
        height: 265px;
    }
}

.RC-footer-info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

/* .RC-footer-info div, */
.RC-footer-info h4 {
    width: 40%;
}

.RC-footer-info h4 {
    font-size: 18px;
    font-weight: normal;
}
.RC-footer-info__details {
    width: 55%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    max-height: 250px;
}
.RC-footer-info h5,
.RC-footer-info__details p {
    color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 1.43;
    font-weight: normal;
    width: 50%;
    margin-bottom: 10px;
}

.RC-footer-info__id span {
    display: block;
}

@media all and (max-width: 992px) {
    .RC-footer-info h5,
    .RC-footer-info__details p {
        font-size: 14px;
    }

    .RC-footer-info h4 {
        font-size: 16px;
    }
}

@media all and (max-width: 767px) {
    .RC-footer-info {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .RC-footer-info h4 {
        width: 100%;
        margin-bottom: 20px;
    }

    .RC-footer-info__details {
        width: 100%;
        -webkit-flex-flow: column nowrap;
                flex-flow: column nowrap;
        height: auto;
    }

    .RC-footer-info h5,
    .RC-footer-info__details p {
        font-size: 12px;
        width: 100%;
    }
}

.RC-footer {
    background-color: rgb(5, 47, 105);
    color: rgb(255, 255, 255);
}

.RC-footer__section {
    border-bottom: 1px solid rgb(238, 238, 238);
}

.RC-footer__section a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.RC-footer__section__logo {
    width: 148px;
    display: block;
}

.RC-footer__section__logo img {
    width: 100%;
}

.RC-footer__section {
    padding: 30px 0;
}

/* .RC-footer__section h4 {
    font-size: 26px;
    font-weight: lighter;
    margin-bottom: 40px;
}

.RC-footer__section h4:nth-of-type(2) {
    border-bottom: 1px solid rgb(238, 238, 238);
    border-top: 1px solid rgb(238, 238, 238);
    padding: 40px 0;
} */

.RC-footer__section:nth-of-type(3) {
    border: none;
}

.RC-footer__icons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 30px 0 0 0;
    width: 30%;
    margin: 0 auto;
}

.RC-footer__icons a {
    width: 34px;
}

.RC-footer__icons img {
    width: 100%;
}

.RC-footer__privacy-policy {
    padding: 40px 0;
    text-align: center;
}

.RC-footer__privacy-policy a {
    text-decoration: none;
    color: rgb(220, 220, 220);
    font-size: 16px;
}

.RC-footer__privacy-policy a:hover {
    color: rgb(220, 220, 220);
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-bottom: 3px;
}

@media all and (max-width: 992px) {
    .RC-footer__section {
        padding: 20px 0;
    }

    .RC-footer__section__logo {
        width: 173px;
    }

    .RC-footer__icons {
        padding: 10px 0 0 0;
    }

    .RC-footer__privacy-policy a {
        font-size: 14px;
    }
}

@media all and (max-width: 767px) {
    .RC-footer {
        padding: 21px 16px;
        height: auto;
    }

    .RC-footer__section__logo {
        margin-left: 0;
        width: 102px;
    }

    .RC-footer__icons {
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-justify-content: center;
                justify-content: center;
        margin: 0;
        padding: 0;
        width: 220px;
    }

    .RC-footer__icons a {
        margin: 5px 8px;
        width: 28px;
    }

    .RC-footer__icons a:first-of-type {
        margin-left: 0;
    }

    .RC-footer__privacy-policy a {
        font-size: 12px;
    }
}

.container {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
}

.container-1280 {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}

.gray-bg {
    background-color: rgb(247, 247, 247);
}

p.text-center,
h1.text-center,
h2.text-center,
h3.text-center,
h4.text-center,
h5.text-center {
    text-align: center;
    margin-bottom: 20px;
}

.section-title-centered {
    text-align: center;
}

.section-title-centered .section-title {
    font-weight: 300;
    padding-bottom: 15px;
}

.section-title-centered .section-title:after {
    left: calc(50% - 28px);
}

.page-lock-scroll {
    overflow: hidden;
}

.page-lock-scroll-mobile,
.page-lock-scroll-header {
    overflow: auto;
}

.pks-applications-iframe {
    width: 100%;
    height: 1200px;
    border: 0;
}

.pks-applications-iframe.membership-fee-app {
    height: 2600px;
}

.image-description {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    width: 60%;
    margin: 50px auto;
}

.arrow-link-list {
    list-style-type: none;
    padding-left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
}

.arrow-link-list-item {
    font-size: 22px;
    padding-bottom: 14px;
    border-bottom: solid 1px rgb(231, 231, 231);
    width: 46%;
    text-align: left;
    margin-bottom: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    color: rgb(5, 47, 105);
    text-decoration: none;
}

.arrow-link-list-item--dark {
    color: white;
}

.arrow-link-list-item__title {
    max-width: 90%;
}

.arrow-link-list-item__arrow {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
}

.arrow-link-list-item__arrow:before {
    content: '';
    height: 2px;
    width: 25px;
    background-color: rgb(5, 47, 105);
    position: absolute;
    top: calc(50% - 1px);
    right: 3px;
}

.arrow-link-list-item__arrow:after {
    content: '';
    border: solid rgb(5, 47, 105);
    border-width: 0 2px 2px 0;
    padding: 3px;
    width: 2px;
    height: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 2px;
}

.arrow-link-list-item:hover .arrow-link-list-item__arrow:before {
    background-color: rgb(224, 27, 34);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}

.arrow-link-list-item:hover .arrow-link-list-item__arrow:after {
    border-color: rgb(224, 27, 34);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}

.text-space-between {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.text-justify {
    text-align: justify;
}

.article-photo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 20px 0;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.article-photo .source {
    font-size: 14px;
}

.article-photo img {
    max-width: 100%;
}

.article-photo.pull-left {
    /* width: 90px; */
    float: left;
    margin: 0 10px 0 0;
}

.article-photo.pull-left img {
    width: 100%;
}

.article-photo.pull-right {
    width: 90px;
    float: right;
}

.article-photo.pull-right img {
    width: 100%;
}

.photo-banner {
    width: 40%;
    margin: 0 auto;
}

.photo-banner img {
    width: 100%;
}

.avatar-img-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    width: 40%;
}

.avatar-img {
    width: 158px;
}

table {
    border-collapse: collapse;
    border: 1px solid #777777;
    margin: 40px auto 0 auto;
    /* width: 100%; */
}

tr:first-of-type {
    background-color: #06377a;
    color: white;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
}

td {
    border-right: solid 1px #777777;
    padding: 12px 16px;
    font-size: 14px;
}

td p.text-center,
td h1.text-center,
td h2.text-center,
td h3.text-center,
td h4.text-center,
td h5.text-center {
    text-align: center;
}

td p {
    margin: 0;
}

tr:nth-child(even) {
    background-color: rgba(6, 55, 122, 0.1);
}

a {
    text-decoration: none;
    color: rgb(6, 55, 122);
}

a:hover {
    text-decoration: none;
    color: rgb(224, 27, 34);
}

.collabsible-container {
    display: -webkit-flex;
    display: flex;
}

.collabsible-container .wrap-collabsible {
    width: 20%;
}

.wrap-collabsible {
    width: 50%;
}

input[type='checkbox'] {
    display: none;
}

.lbl-toggle {
    display: block;
    font-weight: bold;
    font-size: 18px;
    padding: 20px 5px;
    /* width: 20%; */
    text-transform: uppercase;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(5, 47, 105);
    border: solid rgb(4, 41, 92);
    border-width: 0 0 1px 0;
    cursor: pointer;
    transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
    color: rgb(224, 27, 34);
}

.lbl-toggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: 0.7rem;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
    /* width: 20%; */
    line-height: 1.4;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.collapsible-content .content-inner {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 22px 20px 22px;
}

.collapsible-content .content-inner p {
    text-align: left;
}

.collapsible-content .content-inner ul {
    margin-bottom: 0;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 1000px;
}

.toggle:checked + .lbl-toggle::before {
    -webkit-transform: rotate(90deg) translateX(-3px);
            transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.video-container {
    width: 80%;
    margin: 20px auto;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.btn {
    padding: 15px 60px;
    background: transparent;
    border: 1px solid rgb(5, 47, 105);
    box-shadow: none;
    display: inline-block;
    margin-top: 50px;
}

.btn-left {
    display: -webkit-flex;
    display: flex;
}

.btn-left .btn {
    margin: 0 0 40px auto;
}

.btn.btn-center {
    margin: 50px auto;
    display: block;
    width: 30%;
    text-align: center;
    padding: 15px 0;
}

.btn a {
    color: rgb(5, 47, 105);
}

.btn:hover {
    background: rgb(5, 47, 105);
}

.btn:hover a {
    color: rgb(255, 255, 255);
}

@media all and (max-width: 1024px) {
    .page-lock-scroll-tablet {
        overflow: hidden;
    }
}

@media all and (max-width: 992px) {
    .section-title-centered .section-title {
        padding-bottom: 10px;
    }

    .section-title-centered .section-title:after {
        left: calc(50% - 20px);
    }

    a:hover {
        color: rgb(6, 55, 122);
    }

    .arrow-link-list {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-flex-flow: column nowrap;
                flex-flow: column nowrap;
        -webkit-align-items: center;
                align-items: center;
        overflow: hidden;
        width: 100%;
        height: auto;
        margin-bottom: 15px;
        display: block;
        position: relative;
    }

    .arrow-link-list-item {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.81;
        padding-bottom: 15px;
        margin-bottom: 19px;
    }

    .arrow-link-list-item:hover {
        border-bottom: solid 1px rgb(231, 231, 231);
        color: rgb(5, 47, 105);
    }

    .arrow-link-list-item:hover .arrow-link-list-item__arrow:before {
        background-color: rgb(5, 47, 105);
    }

    .arrow-link-list-item:hover .arrow-link-list-item__arrow:after {
        border-color: rgb(5, 47, 105);
    }

    .photo-banner {
        width: 100%;
    }

    .lbl-toggle {
        width: 100%;
    }

    .collapsible-content {
        width: 100%;
    }

    .btn {
        font-size: 14px;
        line-height: 17px;
        padding: 12px 37px;
    }

    .btn:hover {
        background: transparent;
    }

    .btn:hover a {
        color: rgb(5, 47, 105);
    }

    .btn.btn-center {
        width: 50%;
    }

    .avatar-img-container {
        width: 55%;
    }
}

@media all and (max-width: 767px) {
    .section-title-centered .section-title {
        padding-bottom: 7px;
    }

    .section-title-centered .section-title:after {
        left: calc(50% - 15px);
    }

    .page-lock-scroll-mobile,
    .page-lock-scroll-header {
        overflow: hidden;
    }

    .arrow-link-list.expand-list {
        height: 300px;
    }

    .arrow-link-list-item {
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 16px;
    }

    td {
        padding: 5px 8px;
        font-size: 12px;
    }

    .video-container {
        width: 100%;
    }

    .wrap-collabsible {
        width: 100%;
    }

    .lbl-toggle {
        font-size: 16px;
        padding: 15px 0;
    }

    .collapsible-content {
        font-size: 14px;
    }

    .collabsible-container {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .collabsible-container .wrap-collabsible {
        width: 80%;
    }

    .btn {
        padding: 12px 30px;
        margin-top: 25px;
    }

    .btn.btn-center {
        width: 80%;
    }

    .avatar-img-container {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: flex-end;
                align-items: flex-end;
        width: 50%;
    }

    .avatar-img-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        width: 100%;
    }

    .avatar-img {
        width: 118px;
        margin-right: 0;
        margin-bottom: 20px;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

#root {
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-x: hidden;
}

