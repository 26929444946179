.rc-top-news-item {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
}

.rc-top-news__content__left .rc-top-news-item {
    height: 269px;
}

.rc-top-news__content__right .rc-top-news-item {
    height: 100%;
}

.rc-top-news__content__left .rc-top-news-item__content,
.rc-top-news__content__right .rc-top-news-item__content {
    height: 100%;
}

.rc-top-news-item:hover .rc-top-news-item__content {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

.rc-top-news-item__content {
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.rc-top-news-item__content:after {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.rc-top-news-item__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: -110px;
    padding: 0 20px 0 40px;
    position: relative;
    z-index: 2;
}

.rc-top-news-item__title h5 {
    font-size: 18px;
    line-height: 1.28;
    font-weight: 500;
    letter-spacing: -0.4px;
    z-index: 2;
    padding-top: 8px;
}

.rc-top-news-item__title h6 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: rgb(255, 255, 255);
    z-index: 2;
    position: relative;
    padding-bottom: 8px;
}

.rc-top-news-item__title h6:after {
    content: '';
    width: 60.5px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.rc-top-news-item a,
.rc-top-news-item a:visited {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

@media all and (max-width: 992px) {
    .rc-top-news-item:hover .rc-top-news-item__content {
        transform: none;
    }

    .rc-top-news__content__left .rc-top-news-item {
        height: 162px;
    }

    .rc-top-news-item__title {
        margin-top: -74px;
    }

    .rc-top-news-item__title h5 {
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: normal;
    }

    .rc-top-news-item__title h6 {
        font-size: 12px;
        letter-spacing: -0.3px;
        padding-bottom: 6px;
    }

    .rc-top-news-item__title h6:after {
        width: 36px;
    }
}

@media all and (max-width: 767px) {
    .rc-top-news__content__left .rc-top-news-item,
    .rc-top-news__content__right .rc-top-news-item {
        height: 220px;
    }

    .rc-top-news-item__title {
        padding: 0 20px;
        margin-top: -100px;
    }

    .rc-top-news-item__title h5 {
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
    }

    .rc-top-news-item__title h6 {
        font-size: 12px;
        letter-spacing: normal;
    }
}
