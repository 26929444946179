.rc-all-news-item {
    width: 46%;
    height: 230px;
    margin-top: 40px;
    overflow: hidden;
}

.rc-all-news-item a {
    display: flex;
}

.rc-all-news-item__image {
    width: 230px;
    height: 230px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
}

.rc-all-news-item__content {
    flex: 1;
}

.rc-all-news-item__content h5 {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 11px;
}

.rc-all-news-item:hover h5 {
    color: rgb(224, 27, 34);
}

.rc-all-news-item__content h6 {
    font-size: 14px;
    color: rgb(51, 51, 51);
    position: relative;
    padding-bottom: 15px;
    letter-spacing: -0.4px;
}

.rc-all-news-item__content h6:after {
    content: '';
    width: 36px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.rc-all-news-item__content p {
    color: rgb(51, 51, 51);
    font-size: 16px;
    margin-top: 18px;
}

.rc-all-news-item__content p b {
    font-weight: lighter;
}

.rc-all-news-item a,
.rc-all-news-item a:visited {
    text-decoration: none;
    color: rgb(51, 51, 51);
}

@media all and (max-width: 992px) {
    .rc-all-news-item {
        width: 48%;
        height: 100px;
        margin-top: 17px;
    }

    .rc-all-news-item__image {
        width: 100px;
        height: 100px;
        margin-right: 14px;
    }

    .rc-all-news-item__content h5 {
        font-size: 16px;
        padding-bottom: 9px;
        margin-top: 0;
        line-height: 1.31;
    }

    .rc-all-news-item:hover h5 {
        color: rgb(51, 51, 51);
    }

    .rc-all-news-item__content h6 {
        padding-bottom: 0;
    }

    .rc-all-news-item__content h6:after {
        height: 0;
    }
}

@media all and (max-width: 767px) {
    .rc-all-news-item {
        width: 100%;
        height: 80px;
        margin-top: 15px;
    }

    .rc-all-news-item__image {
        width: 80px;
        height: 80px;
        margin-right: 10px;
    }

    .rc-all-news-item__content h5 {
        font-size: 14px;
        padding-bottom: 5px;
    }

    .rc-all-news-item__content h6 {
        font-size: 12px;
        letter-spacing: -0.3px;
    }

    .rc-all-news-item__content p {
        display: none;
    }
}
