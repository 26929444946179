.link-list {
    display: flex;
    flex-flow: column wrap;
    list-style-type: none;
    align-items: flex-start;
}

.link-list li {
    margin-bottom: 10px;
}

.link-list li a {
    text-decoration: none;
    color: rgb(220, 220, 220);
    font-size: 16px;
}

.link-list li a:hover {
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-bottom: 3px;
}

@media all and (max-width: 992px) {
    .link-list li a:hover {
        border-bottom: none;
        padding-bottom: 0;
    }
}
