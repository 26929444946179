.section-title-RC {
    color: rgb(5, 47, 105);
    font-size: 30px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
    /* margin-left: 32px; */
}

/* .section-title-RC:before {
    content: '';
    border: solid rgb(218, 0, 25);
    border-width: 3px 0 0 0;
    display: inline-block;
    width: 24px;
    transform: rotate(-70deg);
    -webkit-transform: rotate(-70deg);
    position: absolute;
    top: 16px;
    left: -26px;
}

.section-title-RC:after {
    content: '';
    border: solid rgb(218, 0, 25);
    border-width: 3px 0 0 0;
    display: inline-block;
    width: 24px;
    transform: rotate(-70deg);
    -webkit-transform: rotate(-70deg);
    position: absolute;
    top: 16px;
    left: -36px;
} */

.section-title-RC.dark-bg-title {
    color: rgb(255, 255, 255);
}

.section-title-RC.dark-bg-title:after,
.section-title-RC.dark-bg-title:before {
    border: solid rgb(5, 47, 105);
    border-width: 3px 0 0 0;
}

@media all and (max-width: 992px) {
    .section-title-RC {
        font-size: 24px;
    }

    .section-title-RC:before,
    .section-title-RC:after {
        width: 21px;
        top: 13px;
    }
}

@media all and (max-width: 767px) {
    .section-title-RC {
        font-size: 20px;
    }

    .section-title-RC:before,
    .section-title-RC:after {
        width: 19px;
        top: 10px;
    }
}
