.open-balkan-top-banner {
    margin-top: 50px;
    width: 100%;
    height: 142.85714285714286vw;
    background-image: url('/images/open-balkan-mobile-visual.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 32px;
    position: relative;
}
@media all and (min-width: 768px) {
    .open-balkan-top-banner {
        margin-top: 100px;
        height: 26.041666666666668vw;
        background-image: url('/images/open-balkan-page-topbanner.png');
    }
}

.open-balkan-section-container {
    width: 100%;
    padding: 32px 32px 42px 32px;
}
.open-balkan-title {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 20px;
}
@media all and (min-width: 768px) {
    .open-balkan-title {
        max-width: 899px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
@media all and (min-width: 768px) {
    h2 {
        font-size: 37px;
    }
}

.open-balkan-section-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    margin-top: 42px;
    margin-bottom: 42px;
}

.open-balkan-subtitle {
    color: #000000;
    font-size: 18px;
    text-align: center;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 24px;
}

@media all and (min-width: 768px) {
    .open-balkan-subtitle {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

.open-balkan-regular-txt {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 40px;
    text-align: justify;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .open-balkan-regular-txt {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 26px;
    }
}

.open-balkan-inner-html-content {
    padding-top: 42px;
    padding-bottom: 42px;
}
.open-balkan-inner-html-content p {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.58;
    margin-bottom: 40px;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .open-balkan-inner-html-content p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.58;
        margin-bottom: 40px;
    }
}

.open-balkan-inner-html-content > h1,
.open-balkan-inner-html-content > h2,
.open-balkan-inner-html-content > h3,
.open-balkan-inner-html-content > h4,
.open-balkan-inner-html-content > h5,
.open-balkan-inner-html-content > h6 {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 18px;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: 32px;
    padding-left: 6vw;
    padding-right: 6vw;
    max-width: 899px;
}
.open-balkan-inner-html-content > ul > li {
    margin-bottom: 20px;
    margin-left: 32px;
    font-size: 20px;
}

@media all and (min-width: 768px) {
    .open-balkan-inner-html-content > h1,
    .open-balkan-inner-html-content > h2,
    .open-balkan-inner-html-content > h3,
    .open-balkan-inner-html-content > h4,
    .open-balkan-inner-html-content > h5,
    .open-balkan-inner-html-content > h6 {
        font-size: 20px;
        margin-bottom: 32px;
    }
}
.open-balkan-inner-html-content p {
    font-size: 16px;
    line-height: 23px;
}
@media all and (min-width: 768px) {
    .open-balkan-inner-html-content p {
        font-size: 18px;
        line-height: 27px;
    }
}

.open-balkan-publish-date-card {
    background-color: #fafafa;
    color: #063779;
    font-family: 'Source Sans Pro';
    position: absolute;
    bottom: -1vw;
    left: 0;
    padding: 2vw 4vw;
    font-size: 12px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .open-balkan-publish-date-card {
        font-size: 14px;
    }
}

.open-balkan-document-wrapper {
    width: 360px;
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.open-balkan-single-document-title {
    color: #939393;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-left: 16px;
}

.open-balkan-document-wrapper:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.open-balkan-aktivnost-headline-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.open-balkan-aktivnost-headline-image {
    width: 100%;
    height: auto;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .open-balkan-aktivnost-headline-image {
        width: 899px;
        margin: 0 auto;
    }
}

.center-txt {
    text-align: center;
}
.dubai-section-title-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.open-balkan-subtitle {
    margin: 24px 0;
    font-size: 20px;
    font-weight: 300;
}

@media all and (min-width: 993px) {
    .open-balkan-subtitle {
        font-size: 30px;
    }
}

.open-balkan-mrgtop {
    margin-top: 24px;
}

.open-balkan-paggination-wrapper {
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #909090;
}

.open-balkan-paggination-wrapper > svg > polygon {
    fill: #909090;
}
.open-balkan-pagination-numbers-wrapper {
    display: flex;
    align-items: center;
}
.open-balkan-pagination-number {
    margin: 0;
    line-height: 30px;
    width: 30px;
    margin-left: 7px;
    margin-right: 7px;
    text-align: center;
}
.open-balkan-pagination-number:hover {
    cursor: pointer;
    color: #063779;
}
.open-balkan-selected-pagination-number {
    color: #ffffff;
    background-color: #063779;
    border-radius: 50%;
}
.open-balkan-pagination-arrows-hover-effect:hover {
    cursor: pointer;
}
.open-balkan-pagination-arrows-hover-effect:hover > svg > polygon {
    fill: #063779;
}
.open-balkan-pagination-arrow-hide-it {
    opacity: 0;
}
.open-balkan-sporazumi-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #e7e7e7;
    color: rgb(6, 55, 122);
}
@media all and (min-width: 768px) {
    .open-balkan-sporazumi-wrapper {
        width: 48%;
        font-size: 20px;
    }
}
.open-balkan-sporazumi-wrapper:hover {
    cursor: pointer;
    color: #e01b22;
}
