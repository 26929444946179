.search-modal-view {
    padding: 0 40px;
}

.search-modal-view .top-section-title h3 {
    margin: 0;
}

.search__search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search__search__bar {
    width: 70%;
    padding: 15px 30px;
    border: none;
    font-size: 16px;
    color: rgb(5, 47, 105);
    border-bottom: 1px solid rgb(5, 47, 105);
}

.search__search__bar:focus {
    outline: none;
}

.search__search__bar::placeholder {
    font-size: 16px;
    color: rgb(5, 47, 105);
}

.search__search__button {
    width: 100px;
    text-align: center;
    color: rgb(5, 47, 105);
    font-size: 20px;
}

.search__search__button:hover {
    color: rgb(224, 27, 34);
    cursor: pointer;
}

@media all and (max-width: 767px) {
    .search-modal-view {
        padding: 0 10px;
    }

    .search__search {
        flex-direction: column;
        align-items: flex-start;
        height: 100px;
    }

    .search__search__bar {
        width: 100%;
        padding: 10px 15px;
        font-size: 14px;
    }
}
