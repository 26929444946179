.events-list__no-events {
    margin-top: 70px;
}

.events-list__no-events img {
    display: block;
    margin: 0 auto;
    margin-bottom: 32px;
    width: 105px;
}

.events-list__no-events__msg {
    text-align: center;
    font-size: 22px;
    line-height: 1.41;
    color: rgb(181, 181, 181);
}

.events-list__no-events--dark .events-list__no-events__msg {
    color: rgb(51, 51, 51);
}

.events-list__no-events__form {
    display: flex;
    width: 565px;
    margin: 40px auto 0 auto;
}

.events-list__no-events__form input {
    flex: 1;
    font-size: 16px;
    padding-left: 30px;
    border-radius: 2px;
    border: solid 1px rgb(181, 181, 181);
    color: rgb(181, 181, 181);
}

.events-list__no-events--dark .events-list__no-events__form input {
    border: solid 1px rgb(105, 105, 105);
}

.events-list__no-events__form input::placeholder {
    color: rgb(181, 181, 181);
    font-style: italic;
}

.events-list__no-events--dark .events-list__no-events__form input::placeholder {
    color: rgb(105, 105, 105);
}

.events-list__no-events__form__button {
    border-radius: 2px;
    border: solid 2px rgb(255, 255, 255);
    background-color: rgb(5, 47, 105);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 60px;
    margin-left: 10px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    position: relative;
}

.events-list__no-events__form__button:hover {
    cursor: pointer;
}

.events-list__no-events__form__button:hover:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.15);
}

@media all and (max-width: 992px) {
    .events-list__no-events {
        margin-top: 40px;
    }

    .events-list__no-events img {
        transform: scale(0.7);
        margin-bottom: 10px;
    }

    .events-list__no-events__msg {
        font-size: 16px;
    }

    .events-list__no-events__form {
        width: 100%;
        flex-direction: column;
        max-width: 403px;
        margin: 20px auto 0 auto;
    }

    .events-list__no-events__form input {
        height: 60px;
        padding: 5px 0 5px 10px;
    }

    .events-list__no-events__form__button {
        margin: 10px auto 0 auto;
        letter-spacing: 1px;
        line-height: 35px;
    }
}

@media all and (max-width: 767px) {
    .events-list__no-events__form__button {
        font-size: 14px;
    }
}
