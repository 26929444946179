.RC-footer {
    background-color: rgb(5, 47, 105);
    color: rgb(255, 255, 255);
}

.RC-footer__section {
    border-bottom: 1px solid rgb(238, 238, 238);
}

.RC-footer__section a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.RC-footer__section__logo {
    width: 148px;
    display: block;
}

.RC-footer__section__logo img {
    width: 100%;
}

.RC-footer__section {
    padding: 30px 0;
}

/* .RC-footer__section h4 {
    font-size: 26px;
    font-weight: lighter;
    margin-bottom: 40px;
}

.RC-footer__section h4:nth-of-type(2) {
    border-bottom: 1px solid rgb(238, 238, 238);
    border-top: 1px solid rgb(238, 238, 238);
    padding: 40px 0;
} */

.RC-footer__section:nth-of-type(3) {
    border: none;
}

.RC-footer__icons {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0 0;
    width: 30%;
    margin: 0 auto;
}

.RC-footer__icons a {
    width: 34px;
}

.RC-footer__icons img {
    width: 100%;
}

.RC-footer__privacy-policy {
    padding: 40px 0;
    text-align: center;
}

.RC-footer__privacy-policy a {
    text-decoration: none;
    color: rgb(220, 220, 220);
    font-size: 16px;
}

.RC-footer__privacy-policy a:hover {
    color: rgb(220, 220, 220);
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-bottom: 3px;
}

@media all and (max-width: 992px) {
    .RC-footer__section {
        padding: 20px 0;
    }

    .RC-footer__section__logo {
        width: 173px;
    }

    .RC-footer__icons {
        padding: 10px 0 0 0;
    }

    .RC-footer__privacy-policy a {
        font-size: 14px;
    }
}

@media all and (max-width: 767px) {
    .RC-footer {
        padding: 21px 16px;
        height: auto;
    }

    .RC-footer__section__logo {
        margin-left: 0;
        width: 102px;
    }

    .RC-footer__icons {
        flex-flow: row wrap;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 220px;
    }

    .RC-footer__icons a {
        margin: 5px 8px;
        width: 28px;
    }

    .RC-footer__icons a:first-of-type {
        margin-left: 0;
    }

    .RC-footer__privacy-policy a {
        font-size: 12px;
    }
}
