.RC-hamburger-navigation-list {
    display: none;
}

@media all and (max-width: 1024px) {
    .RC-hamburger-navigation-list {
        align-items: center;
        background-color: rgb(5, 47, 105);
        border-top: solid 1px rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: flex-start;
        padding-bottom: 13px;
        position: absolute;
        right: 0;
        top: 100%;
        width: 320px;
        z-index: 2;
    }

    .RC-hamburger-navigation-list__chamber-title {
        color: rgb(255, 255, 255);
        font-size: 17px;
        font-weight: normal;
        letter-spacing: -1px;
        line-height: 20px;
        padding: 50px 50px 10px 50px;
        text-align: center;
    }

    .RC-hamburger-navigation-list__nav {
        margin-top: 15px;
        list-style-type: none;
        width: 100%;
        margin-bottom: 25px;
    }

    .RC-hamburger-navigation-list__nav li {
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center;
        border-bottom: solid 1px rgba(233, 153, 155, 0.6);
    }

    .RC-hamburger-navigation-list__nav li a {
        color: rgb(255, 255, 255);
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .RC-hamburger-navigation-list__nav .is-active {
        color: rgb(224, 27, 34);
    }

    .RC-hamburger-navigation-list__options {
        display: flex;
        list-style-type: none;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 65px;
        cursor: pointer;
    }

    .RC-hamburger-navigation-list__options li {
        font-size: 16px;
        color: rgb(255, 255, 255);
        text-align: center;
        font-weight: normal;
        letter-spacing: -1px;
    }

    .RC-hamburger-navigation-list__options li.active-language {
        color: rgb(224, 27, 34);
    }

    .RC-hamburger-navigation-list__options li:nth-of-type(1) {
        border-right: 1px solid rgb(203, 203, 203);
        margin-right: 24px;
        padding-right: 24px;
    }

    .RC-hamburger-navigation-list__call-center {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -1px;
    }
}

@media all and (max-width: 767px) {
    .RC-hamburger-navigation-list {
        width: 100%;
        z-index: 2;
    }

    .RC-hamburger-navigation-list__chamber-title {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        padding: 12px 50px;
    }

    .RC-hamburger-navigation-list__nav {
        margin-top: 0;
    }

    .RC-hamburger-navigation-list__nav li {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .RC-hamburger-navigation-list__options {
        margin-top: 46px;
        margin-bottom: 72px;
    }

    .RC-hamburger-navigation-list__call-center {
        letter-spacing: 0;
    }
}
