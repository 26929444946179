.container {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
}

.container-1280 {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}

.gray-bg {
    background-color: rgb(247, 247, 247);
}

p.text-center,
h1.text-center,
h2.text-center,
h3.text-center,
h4.text-center,
h5.text-center {
    text-align: center;
    margin-bottom: 20px;
}

.section-title-centered {
    text-align: center;
}

.section-title-centered .section-title {
    font-weight: 300;
    padding-bottom: 15px;
}

.section-title-centered .section-title:after {
    left: calc(50% - 28px);
}

.page-lock-scroll {
    overflow: hidden;
}

.page-lock-scroll-mobile,
.page-lock-scroll-header {
    overflow: auto;
}

.pks-applications-iframe {
    width: 100%;
    height: 1200px;
    border: 0;
}

.pks-applications-iframe.membership-fee-app {
    height: 2600px;
}

.image-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: 50px auto;
}

.arrow-link-list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: center;
}

.arrow-link-list-item {
    font-size: 22px;
    padding-bottom: 14px;
    border-bottom: solid 1px rgb(231, 231, 231);
    width: 46%;
    text-align: left;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: rgb(5, 47, 105);
    text-decoration: none;
}

.arrow-link-list-item--dark {
    color: white;
}

.arrow-link-list-item__title {
    max-width: 90%;
}

.arrow-link-list-item__arrow {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
}

.arrow-link-list-item__arrow:before {
    content: '';
    height: 2px;
    width: 25px;
    background-color: rgb(5, 47, 105);
    position: absolute;
    top: calc(50% - 1px);
    right: 3px;
}

.arrow-link-list-item__arrow:after {
    content: '';
    border: solid rgb(5, 47, 105);
    border-width: 0 2px 2px 0;
    padding: 3px;
    width: 2px;
    height: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 2px;
}

.arrow-link-list-item:hover .arrow-link-list-item__arrow:before {
    background-color: rgb(224, 27, 34);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}

.arrow-link-list-item:hover .arrow-link-list-item__arrow:after {
    border-color: rgb(224, 27, 34);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}

.text-space-between {
    display: flex;
    justify-content: space-between;
}

.text-justify {
    text-align: justify;
}

.article-photo {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
}

.article-photo .source {
    font-size: 14px;
}

.article-photo img {
    max-width: 100%;
}

.article-photo.pull-left {
    /* width: 90px; */
    float: left;
    margin: 0 10px 0 0;
}

.article-photo.pull-left img {
    width: 100%;
}

.article-photo.pull-right {
    width: 90px;
    float: right;
}

.article-photo.pull-right img {
    width: 100%;
}

.photo-banner {
    width: 40%;
    margin: 0 auto;
}

.photo-banner img {
    width: 100%;
}

.avatar-img-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 40%;
}

.avatar-img {
    width: 158px;
}

table {
    border-collapse: collapse;
    border: 1px solid #777777;
    margin: 40px auto 0 auto;
    /* width: 100%; */
}

tr:first-of-type {
    background-color: #06377a;
    color: white;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
}

td {
    border-right: solid 1px #777777;
    padding: 12px 16px;
    font-size: 14px;
}

td p.text-center,
td h1.text-center,
td h2.text-center,
td h3.text-center,
td h4.text-center,
td h5.text-center {
    text-align: center;
}

td p {
    margin: 0;
}

tr:nth-child(even) {
    background-color: rgba(6, 55, 122, 0.1);
}

a {
    text-decoration: none;
    color: rgb(6, 55, 122);
}

a:hover {
    text-decoration: none;
    color: rgb(224, 27, 34);
}

.collabsible-container {
    display: flex;
}

.collabsible-container .wrap-collabsible {
    width: 20%;
}

.wrap-collabsible {
    width: 50%;
}

input[type='checkbox'] {
    display: none;
}

.lbl-toggle {
    display: block;
    font-weight: bold;
    font-size: 18px;
    padding: 20px 5px;
    /* width: 20%; */
    text-transform: uppercase;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(5, 47, 105);
    border: solid rgb(4, 41, 92);
    border-width: 0 0 1px 0;
    cursor: pointer;
    transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
    color: rgb(224, 27, 34);
}

.lbl-toggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(-2px);
    transition: transform 0.2s ease-out;
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
    /* width: 20%; */
    line-height: 1.4;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.collapsible-content .content-inner {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 22px 20px 22px;
}

.collapsible-content .content-inner p {
    text-align: left;
}

.collapsible-content .content-inner ul {
    margin-bottom: 0;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 1000px;
}

.toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.video-container {
    width: 80%;
    margin: 20px auto;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.btn {
    padding: 15px 60px;
    background: transparent;
    border: 1px solid rgb(5, 47, 105);
    box-shadow: none;
    display: inline-block;
    margin-top: 50px;
}

.btn-left {
    display: flex;
}

.btn-left .btn {
    margin: 0 0 40px auto;
}

.btn.btn-center {
    margin: 50px auto;
    display: block;
    width: 30%;
    text-align: center;
    padding: 15px 0;
}

.btn a {
    color: rgb(5, 47, 105);
}

.btn:hover {
    background: rgb(5, 47, 105);
}

.btn:hover a {
    color: rgb(255, 255, 255);
}

@media all and (max-width: 1024px) {
    .page-lock-scroll-tablet {
        overflow: hidden;
    }
}

@media all and (max-width: 992px) {
    .section-title-centered .section-title {
        padding-bottom: 10px;
    }

    .section-title-centered .section-title:after {
        left: calc(50% - 20px);
    }

    a:hover {
        color: rgb(6, 55, 122);
    }

    .arrow-link-list {
        justify-content: space-between;
        flex-flow: column nowrap;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: auto;
        margin-bottom: 15px;
        display: block;
        position: relative;
    }

    .arrow-link-list-item {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.81;
        padding-bottom: 15px;
        margin-bottom: 19px;
    }

    .arrow-link-list-item:hover {
        border-bottom: solid 1px rgb(231, 231, 231);
        color: rgb(5, 47, 105);
    }

    .arrow-link-list-item:hover .arrow-link-list-item__arrow:before {
        background-color: rgb(5, 47, 105);
    }

    .arrow-link-list-item:hover .arrow-link-list-item__arrow:after {
        border-color: rgb(5, 47, 105);
    }

    .photo-banner {
        width: 100%;
    }

    .lbl-toggle {
        width: 100%;
    }

    .collapsible-content {
        width: 100%;
    }

    .btn {
        font-size: 14px;
        line-height: 17px;
        padding: 12px 37px;
    }

    .btn:hover {
        background: transparent;
    }

    .btn:hover a {
        color: rgb(5, 47, 105);
    }

    .btn.btn-center {
        width: 50%;
    }

    .avatar-img-container {
        width: 55%;
    }
}

@media all and (max-width: 767px) {
    .section-title-centered .section-title {
        padding-bottom: 7px;
    }

    .section-title-centered .section-title:after {
        left: calc(50% - 15px);
    }

    .page-lock-scroll-mobile,
    .page-lock-scroll-header {
        overflow: hidden;
    }

    .arrow-link-list.expand-list {
        height: 300px;
    }

    .arrow-link-list-item {
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 16px;
    }

    td {
        padding: 5px 8px;
        font-size: 12px;
    }

    .video-container {
        width: 100%;
    }

    .wrap-collabsible {
        width: 100%;
    }

    .lbl-toggle {
        font-size: 16px;
        padding: 15px 0;
    }

    .collapsible-content {
        font-size: 14px;
    }

    .collabsible-container {
        flex-direction: column;
    }

    .collabsible-container .wrap-collabsible {
        width: 80%;
    }

    .btn {
        padding: 12px 30px;
        margin-top: 25px;
    }

    .btn.btn-center {
        width: 80%;
    }

    .avatar-img-container {
        justify-content: space-between;
        align-items: flex-end;
        width: 50%;
    }

    .avatar-img-container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .avatar-img {
        width: 118px;
        margin-right: 0;
        margin-bottom: 20px;
    }
}
