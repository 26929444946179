.error-section {
    text-align: center;
    max-width: 1280px;
    width: 100%;
    margin: 30px auto;
    padding: 50px 0;
}

.error-section__title .section-title {
    font-size: 50px;
}

.error-section__text {
    font-size: 16px;
    margin-top: 56px;
}

.error-section__reload {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background-color: rgb(5, 47, 105);
    display: inline-block;
    color: white;
    padding: 18px 25px;
    border-radius: 2px;
    margin-top: 35px;
}

.error-section__reload:hover {
    cursor: pointer;
}

@media all and (max-width: 992px) {
    .error-section {
        margin: 20px auto;
        padding: 30px 0;
    }
    .error-section__title .section-title {
        font-size: 36px;
    }
    .error-section__text {
        margin-top: 36px;
    }

    .error-section__reload {
        margin-top: 25px;
    }
}

@media all and (max-width: 767px) {
    .error-section__title .section-title {
        font-size: 28px;
    }
    .error-section__text {
        font-size: 14px;
        margin-top: 26px;
    }

    .error-section__reload {
        font-size: 12px;
        margin-top: 25px;
    }
}
