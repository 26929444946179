.search-button {
    height: 100%;
    display: flex;
    align-items: top;
}

.search-button:hover {
    color: rgb(224, 27, 34);
    cursor: pointer;
}
