.dubai-expo-2020-top-banner {
    margin-top: 50px;
    width: 100%;
    height: 142.85714285714286vw;
    background-image: url('/images/expo-dubai-header-700x1000.jpg');
    /* background-image: url('/images/expo-dubai-header-700x1000-02.jpg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 32px;
    position: relative;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-top-banner {
        margin-top: 100px;
        height: 26.041666666666668vw;
        background-image: url('/images/expo-dubai-header-1920x500.jpg');
        /* background-image: url('/images/expo-dubai-header-1920x500-02.jpg'); */
    }
}

.dubai-expo-2020-section-container {
    width: 100%;
    padding: 32px 32px 42px 32px;
}
.dubai-expo-2020-title {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 20px;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-title {
        max-width: 899px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
@media all and (min-width: 768px) {
    h2 {
        font-size: 37px;
    }
}

.dubai-expo-2020-section-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 42px;
}

.dubai-expo-2020-subtitle {
    color: #000000;
    font-family: 'Playfair Display';
    font-size: 18px;
    text-align: center;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 24px;
}

@media all and (min-width: 768px) {
    .dubai-expo-2020-subtitle {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

.dubai-expo-2020-regular-txt {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.58;
    margin-bottom: 40px;
    text-align: justify;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-regular-txt {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 26px;
    }
}

.dubai-expo-2020-inner-html-content {
    padding-top: 42px;
    padding-bottom: 42px;
}
.dubai-expo-2020-inner-html-content p {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.58;
    margin-bottom: 40px;
    text-align: justify;
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-inner-html-content p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.58;
        margin-bottom: 40px;
    }
}

.dubai-expo-2020-inner-html-content > h1,
.dubai-expo-2020-inner-html-content > h2,
.dubai-expo-2020-inner-html-content > h3,
.dubai-expo-2020-inner-html-content > h4,
.dubai-expo-2020-inner-html-content > h5,
.dubai-expo-2020-inner-html-content > h6 {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 18px;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: 32px;
    padding-left: 6vw;
    padding-right: 6vw;
    max-width: 899px;
}

@media all and (min-width: 768px) {
    .dubai-expo-2020-inner-html-content > h1,
    .dubai-expo-2020-inner-html-content > h2,
    .dubai-expo-2020-inner-html-content > h3,
    .dubai-expo-2020-inner-html-content > h4,
    .dubai-expo-2020-inner-html-content > h5,
    .dubai-expo-2020-inner-html-content > h6 {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

.dubai-expo-2020-publish-date-card {
    background-color: #fafafa;
    color: #063779;
    font-family: 'Source Sans Pro';
    position: absolute;
    bottom: -1vw;
    left: 0;
    padding: 2vw 4vw;
    font-size: 12px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .dubai-expo-2020-publish-date-card {
        font-size: 14px;
    }
}

.dubai-expo-2020-document-wrapper {
    width: 360px;
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.dubai-expo-2020-single-document-title {
    color: #939393;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-left: 16px;
}

.dubai-expo-2020-document-wrapper:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.dubai-expo-aktivnost-headline-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.dubai-expo-aktivnost-headline-image {
    width: 100%;
    height: auto;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
@media all and (min-width: 768px) {
    .dubai-expo-aktivnost-headline-image {
        width: 899px;
        margin: 0 auto;
    }
}

.center-txt {
    text-align: center;
}
.dubai-section-title-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.dubai-expo-subtitle {
    margin: 24px 0;
    font-size: 20px;
    font-weight: 300;
}

@media all and (min-width: 993px) {
    .dubai-expo-subtitle {
        font-size: 30px;
    }
}

.dubaiexpo-mrgtop {
    margin-top: 24px;
}
