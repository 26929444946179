.additional-info {
    margin-bottom: 28px;
}

.additional-info a {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
    padding-right: 11px;
    text-underline-position: under;
    margin-right: 11px;
    color: rgb(255, 255, 255);
    line-height: 1.4;
    border-right: solid 1px rgb(255, 255, 255);
}

.additional-info a:last-of-type {
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
}

@media all and (max-width: 992px) {
    .additional-info {
        display: flex;
        flex-direction: column;
    }

    .additional-info a {
        border-right: none;
    }

    .additional-info a:nth-of-type(1) {
        margin-bottom: 16px;
    }
}

@media all and (max-width: 767px) {
    .additional-info {
        margin-bottom: 0;
    }

    .additional-info a {
        font-size: 12px;
        border-right: none;
    }

    .additional-info a:nth-of-type(1) {
        margin-bottom: 14px;
    }
}
