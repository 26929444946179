.footer-info {
    display: flex;
    justify-content: space-between;
}

.footer-info div {
    flex: 1;
    margin: 0 5px;
}

.footer-info h5,
.footer-info .footer-info__email {
    color: rgb(130, 155, 188);
    font-size: 16px;
    line-height: 1.43;
    font-weight: normal;
    margin: 8px 0;
}

.footer-info .footer-info__email:hover {
    color: rgb(255, 255, 255);
}

.footer-info span {
    display: block;
}

@media all and (max-width: 992px) {
    .footer-info h5 {
        font-size: 15px;
        margin: 0;
    }
}

@media all and (max-width: 767px) {
    .footer__section:nth-of-type(3) .footer-info {
        flex-direction: column;
    }

    .footer-info div {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .footer-info div:nth-of-type(2) {
        margin: 15px 0 25px 0;
    }

    .footer-info div:nth-of-type(3) {
        margin-bottom: 0;
    }

    .footer-info div:last-of-type h5:nth-of-type(1) {
        margin-top: 0;
    }

    .footer-info h5 {
        line-height: 18px;
        font-size: 12px;
    }
}
