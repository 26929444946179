.events-item {
    width: 380px;
    height: 240px;
    border: solid 1px rgb(221, 221, 221);
    background-color: rgb(255, 255, 255);
    margin-bottom: 20px;
    margin-right: 15px;
    box-shadow: 0 22px 14px -15px rgba(0, 0, 0, 0.15);
}

.events-item:nth-of-type(3n) {
    margin-right: 0;
}

.events-item:hover {
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.events-item a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 35px 18px;
}

.events-item__image {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
}

.events-section__content.events-section_content--center .events-item {
    margin-right: 16px;
}

.events-item__content {
    width: 100%;
}

.events-item__content.events-item__content--width-image {
    width: 75%;
}

.events-item__content__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.events-item__content__info:after {
    content: '';
    width: 39px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    bottom: -12px;
    left: 0;
}

.events-item__content__info h6 {
    color: rgb(51, 51, 51);
    font-size: 15px;
}

.events-item__content__info__category {
    padding: 5px 20px;
    border-radius: 30px;
    display: flex;
    text-align: center;
}

.events-item__content__info__category div {
    margin: auto;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 13px;
}

.events-item h5 {
    position: relative;
    font-size: 17px;
    margin-top: 20px;
    line-height: 1.29;
}

.events-item p {
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.43;
    color: rgb(51, 51, 51);
}

.events-item a,
.events-item a:visited {
    text-decoration: none;
    color: rgb(5, 47, 105);
}

.events__content p b {
    font-weight: lighter;
}

@media all and (max-width: 1332px) {
    .events-item {
        width: 340px;
        margin-right: 15px;
    }
}

@media all and (max-width: 1332px) {
    .events-item {
        /* flex-basis: calc((100% - 30px) / 3); */
        /* width: 300px; */
        margin-right: 12px;
    }

    .events-item:nth-of-type(3n) {
        margin-right: 15px;
    }
}

@media all and (max-width: 992px) {
    .events-item {
        flex-basis: calc(50% - 7.5px);
    }

    .events-item:nth-of-type(3n) {
        margin-right: 15px;
    }

    .events-item:nth-of-type(2n) {
        margin-right: 0;
    }
}

@media all and (max-width: 767px) {
    .events-item {
        width: 100%;
        min-width: 100%;
        height: 100%;
        background-color: rgb(247, 247, 247);
        margin-bottom: 0;
        margin-right: 0;
        border: none;
        border-bottom: solid 2px rgba(181, 181, 181, 0.6);
        background-image: none;
        box-shadow: none;
    }

    .events-item--dark.events-item {
        background-color: transparent;
    }

    .events-item:hover {
        box-shadow: none;
        transform: none;
    }

    .events-item a {
        padding: 17px 0;
        justify-content: flex-end;
        flex-direction: row-reverse;
    }

    .events-item__content {
        margin-right: 16px;
    }

    .events-item__content__info {
        justify-content: flex-start;
    }

    .events-item__content__info:after {
        bottom: -2px;
    }

    .events-item__content__info h6 {
        font-size: 14px;
        color: rgb(119, 119, 119);
        margin-right: 30px;
    }

    .events-item--dark .events-item__content__info h6 {
        color: rgb(255, 255, 255);
        font-weight: 500;
    }

    .events-item h5 {
        font-size: 16px;
        padding-bottom: 0;
        margin-top: 13px;
    }

    .events-item--dark.events-item a,
    .events-item--dark.events-item a:visited {
        color: rgb(255, 255, 255);
    }

    .events-item--dark.events-item h5 {
        font-weight: 500;
    }

    .events-item p {
        display: none;
    }
}
