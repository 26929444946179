.date-event-item {
    color: rgb(5, 47, 105);
    display: block;
    text-decoration: none;
    padding: 5px;
    border-bottom: solid 1px rgba(5, 47, 105);
    margin: 20px 0;
}

.date-event-item--dark {
    color: white;
    border-bottom: 1px solid white;
}

.date-event-item:hover {
    border-bottom: solid 1px rgba(224, 27, 34);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}

.date-event-item:hover .date-event-item__title {
    color: rgb(224, 27, 34);
}

.date-event-item__info {
    display: flex;
    justify-content: space-between;
}

.date-event-item__info__date {
    position: relative;
    font-size: 16px;
}

.date-event-item__info__date:after {
    content: '';
    width: 39px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    top: 85%;
    left: 0;
}

.date-event-item__info__label {
    font-size: 14px;
}

.date-event-item__info__label {
    padding: 5px 20px;
    border-radius: 30px;
    display: flex;
    text-align: center;
}

.date-event-item__info__label div {
    margin: auto;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 13px;
}

.date-event-item__title {
    font-size: 16px;
    line-height: 1.48;
    margin: 10px 0;
}

@media all and (max-width: 767px) {
    .date-event-item__info__label {
        font-size: 12px;
    }

    .date-event-item__title {
        font-size: 14px;
        line-height: 1.48;
        margin: 10px 0;
    }

    .date-event-item__info__date {
        font-size: 14px;
    }
}
