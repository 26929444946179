.header-nav__nav {
    display: flex;

    list-style-type: none;
    height: 100%;
}

.header-nav__nav a {
    text-decoration: none;
    color: rgb(5, 47, 105);
    font-size: 18px;
    font-weight: 500;
    margin: 0 25px;
}

.header-nav__nav a:hover,
.header-nav__nav a.is-active {
    color: rgb(224, 27, 34);
}

@media all and (max-width: 1195px) {
    .header-nav__nav a {
        margin: 0 16px;
    }

    .header-nav__nav li:first-of-type a {
        margin-left: 0;
    }

    .header-nav__nav li:last-of-type a {
        margin-right: 0;
    }
}

@media all and (max-width: 1080px) {
    .header-nav__nav a {
        font-size: 18px;
        margin: 0 12px;
    }

    .header-nav__nav li:first-of-type a {
        margin-left: 0;
    }

    .header-nav__nav li:last-of-type a {
        margin-right: 0;
    }
}

@media all and (max-width: 1040px) {
    .header-nav__nav a {
        font-size: 17px;
        margin: 0 10px;
    }
}

@media all and (max-width: 998px) {
    .header-nav__nav a {
        font-size: 16px;
    }

    .header-nav__nav a:hover {
        color: rgb(5, 47, 105);
    }
}

@media all and (max-width: 992px) {
    .header-nav__nav a {
        font-size: 14px;
        margin: 0 10px;
    }
}

@media all and (max-width: 970px) {
    .header-nav__nav a {
        font-size: 13px;
        margin: 0 8px;
    }
}

@media all and (max-width: 883px) {
    .header-nav__nav a {
        font-size: 11px;
    }
}

@media all and (max-width: 819px) {
    .header-nav__nav a {
        margin: 0 7px;
    }
}
