.footer {
    background-color: rgb(6, 55, 122);
    color: rgb(238, 238, 238);
    padding: 60px 80px 60px 80px;
}

.footer__section {
    border-bottom: 1px solid rgb(238, 238, 238);
}

.footer__section a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.footer__section:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}

.footer__section__logo {
    width: 148px;
}

.footer__section__logo img {
    width: 100%;
}

.footer__section:nth-of-type(1) .link-list {
    justify-content: space-between;
}

.footer__section:nth-of-type(2),
.footer__section:nth-of-type(4) {
    padding: 40px 0 30px 0;
}

.footer__section:nth-of-type(2) .link-list {
    height: 128px;
}

.footer__section:nth-of-type(2) .link-list li {
    width: 25%;
}

.footer__section h4 {
    font-size: 26px;
    font-weight: lighter;
    margin-bottom: 40px;
}

.footer__section h4:nth-of-type(2) {
    border-bottom: 1px solid rgb(238, 238, 238);
    border-top: 1px solid rgb(238, 238, 238);
    padding: 40px 0;
}

.footer__section:nth-of-type(3) {
    border: none;
    padding-top: 40px;
}

.footer__icons {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 50px 0 0 0;
}

.footer__icons img {
    margin: 0 20px;
    width: 34px;
}

.footer__privacy-policy {
    padding: 40px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__privacy-policy a {
    text-decoration: none;
    color: rgb(220, 220, 220);
    font-size: 16px;
    margin-bottom: 32px;
}

.footer__privacy-policy a:hover {
    color: rgb(255, 255, 255);
}

@media all and (max-width: 992px) {
    .footer {
        padding: 56px 86px 30px 86px;
    }

    .footer__section:nth-of-type(1) {
        padding-bottom: 30px;
    }

    .footer__section__logo {
        /*remove this margin if centered?*/
        margin-left: -50px;
        width: 173px;
    }

    .footer__section .link-list li {
        margin-bottom: 15px;
    }

    .footer__section .link-list li a {
        font-size: 16px;
    }

    .footer__section:nth-of-type(2),
    .footer__section:nth-of-type(4) {
        padding: 30px 0 15px 0;
    }

    .footer__section:nth-of-type(2) .link-list {
        height: 155px;
    }

    .footer__section h4 {
        font-size: 21px;
        font-weight: normal;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    .footer__section h4:nth-of-type(2) {
        padding: 30px 0;
    }

    .footer__section:nth-of-type(3) {
        padding-top: 23px;
    }

    .footer__icons {
        padding: 30px 0;
    }

    .footer__privacy-policy a {
        font-size: 14px;
    }
}

@media all and (max-width: 767px) {
    .footer {
        padding: 21px 16px;
        height: auto;
    }

    .footer__section:nth-of-type(1) {
        padding-right: 0;
        padding-bottom: 6px;
    }

    .footer__section__logo {
        margin-left: 0;
        width: 102px;
    }

    .footer__section:nth-of-type(1) .link-list {
        width: 79px;
    }

    .footer__section:nth-of-type(1) .link-list li a {
        font-size: 14px;
    }

    .footer__section:nth-of-type(2),
    .footer__section:nth-of-type(4) {
        padding-top: 20px;
        padding-bottom: 6px;
    }

    .footer__section:nth-of-type(2) .link-list {
        height: 360px;
        width: 100%;
    }

    .footer__section:nth-of-type(2) .link-list li {
        flex: 0 1 auto;
        margin: 10px 0;
    }

    .footer__section:nth-of-type(2) .link-list li a {
        font-size: 12px;
    }

    .footer__section h4 {
        font-size: 17px;
        margin-bottom: 20px;
    }

    .footer__section h4:nth-of-type(2) {
        margin-bottom: 5px;
        padding: 20px 0;
    }

    .footer__section:nth-of-type(3) {
        padding-top: 15px;
    }

    .footer__icons {
        padding: 5px 0 0 0;
    }

    .footer__icons img {
        margin: 10px 16px;
        width: 28px;
        height: 28px;
    }

    .footer__privacy-policy a {
        font-size: 12px;
    }
}

.cookies-table-wrapper {
    width: 100%;
    overflow-x: scroll;
}
.cookies-table-wrapper td {
    white-space: nowrap;
}
.cookies-thead {
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    font-weight: 500;
}

.gdpr-notification-container {
    width: 100%;
    padding: 10px 25px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9000;
    background-color: rgba(0, 37, 70, 0.92);
}
@media only screen and (min-width: 969px) {
    .gdpr-notification-container {
        flex-direction: row;
        justify-content: space-around;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
.gdpr-notification-txt {
    font-size: 12px;
    color: #fff;
    line-height: 16px;
}
@media only screen and (min-width: 720px) {
    .gdpr-notification-txt {
        font-size: 14px;
        line-height: 20px;
    }
}
.gdpr-notification-link {
    text-decoration: underline;
    color: #fff;
}
.gdpr-notification-link:hover {
    text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
}
.gdpr-notification-accept-btn {
    padding: 5px 25px;
    border-radius: 10px;
    color: rgb(0, 37, 70);
    background-color: #fff;
    border: 1px solid #fff;
    font-size: 14px;
    margin-top: 20px;
}
@media only screen and (min-width: 969px) {
    .gdpr-notification-accept-btn {
        margin-top: 0;
    }
}
.gdpr-notification-accept-btn:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.9);
}
.gdpr-notification-close-btn-wrapper {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -16px;
    right: 0;
    background-color: rgba(0, 37, 70, 0.92);
    border-radius: 50%;
}
.gdpr-notification-close-btn-wrapper:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 5px #fff);
}
.gdpr-notification-close-btn {
    width: 100%;
    height: 100%;
}
