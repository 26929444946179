.RC-link-list {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    align-items: flex-start;
    height: 130px;
}

.RC-link-list li {
    margin-bottom: 10px;
    width: 25%;
}

.RC-link-list li a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
}

.RC-link-list li a:hover {
    border-bottom: 1px solid rgb(255, 255, 255);
    padding-bottom: 3px;
}

@media all and (max-width: 992px) {
    .RC-link-list li a {
        font-size: 14px;
    }

    .RC-link-list li a:hover {
        border-bottom: none;
        padding-bottom: 0;
    }
}

@media all and (max-width: 767px) {
    .RC-link-list li a {
        font-size: 12px;
    }

    .RC-link-list {
        flex-flow: column wrap;
        height: 265px;
    }
}
