.page-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 300px;
    padding: 30px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgb(5, 47, 105);
}

.page-info.page-info--gray {
    background-color: rgb(51, 51, 51);
    background-image: none;
}

.page-info .container-1280 {
    width: 97%;
    height: 100%;
}

.page-info.page-info--overlay {
    position: relative;
}

.page-info.page-info--overlay:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(5, 47, 105, 0.8);
}

.page-info.page-info--overlay .page-info__breadcrumbs {
    position: relative;
    z-index: 1;
}

.page-info.page-info--overlay .page-info__content {
    position: relative;
    z-index: 1;
}

.page-info__breadcrumbs {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 16px;
    margin-left: -6px;
}

.page-info__breadcrumbs__item {
    font-size: 14px;
    height: 16px;
    margin: 0 14px;
    position: relative;
}

.page-info__breadcrumbs__item:nth-of-type(1) {
    margin-left: 10px;
}

.page-info__breadcrumbs__item:after {
    content: '';
    border: solid rgb(224, 27, 34);
    border-width: 0 2px 2px 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    top: 4px;
    right: -17px;
}

.page-info__breadcrumbs__item:nth-last-of-type(1):after {
    border: none;
}

.page-info__breadcrumbs__item a {
    color: rgb(255, 255, 255);
    font-size: 14px;
    height: 16px;
    text-decoration: none;
    font-weight: 500;
}

.page-info__breadcrumbs__item:last-of-type a {
    font-weight: normal;
}

.page-info__breadcrumbs__item:nth-last-of-type(1) a:hover,
.page-info__breadcrumbs__item:nth-last-of-type(1):hover {
    cursor: context-menu;
}

.page-info__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 0 100px;
    height: 100%;
    align-items: center;
}

.page-info__content__title .section-title {
    text-transform: uppercase;
    font-weight: 500;
}

.page-info__content__image {
    width: 45%;
}

.page-info__content__image img {
    width: 75%;
}

.page-info.page-info--gray .page-info__content__image {
    width: 150px;
}

@media all and (max-width: 992px) {
    .page-info {
        height: 250px;
    }

    .page-info__breadcrumbs {
        display: none;
    }

    .page-info__content {
        padding: 0 0 0 25px;
    }

    .page-info__content__image {
        width: 35%;
    }
}

@media all and (max-width: 767px) {
    .page-info {
        height: auto;
    }
}
