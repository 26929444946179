/* .RC-page {
    margin-top: 210px;
} */

/* @media all and (max-width: 992px) {
    .RC-page {
        margin-top: 0;
    }
} */

@media all and (max-width: 767px) {
    .RC-page {
        margin-top: 75px;
    }
}
