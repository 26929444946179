.not-found-page h4 {
    color: rgb(5, 47, 105);
    font-size: 200px;
    text-align: center;
    margin-bottom: 50px;
}

.not-found-page__title {
    color: rgb(5, 47, 105);
    font-size: 50px;
    text-align: center;
    margin-bottom: 50px;
}

@media all and (max-width: 992px) {
    .not-found-page h4 {
        font-size: 100px;
        margin-bottom: 30px;
    }

    .not-found-page__title {
        font-size: 40px;
    }
}

@media all and (max-width: 767px) {
    .not-found-page h4 {
        font-size: 100px;
    }

    .not-found-page__title {
        font-size: 30px;
    }
}
