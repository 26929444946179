.search-no-results {
    text-align: center;
    padding: 100px 0;
    color: rgb(153, 153, 153);
}

.search-no-results__icon {
    font-size: 80px;
}

.search-no-results__text {
    font-size: 30px;
    margin-top: 50px;
}

@media all and (max-width: 767px) {
    .search-no-results {
        padding: 50px 0;
    }
    .search-no-results__icon {
        font-size: 40px;
    }

    .search-no-results__text {
        font-size: 20px;
        margin-top: 25px;
    }
}
