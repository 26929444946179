.dark-blue {
    background-color: rgb(5, 47, 105);
}

.rc-section-container.container-1280 {
    padding: 50px 52px 50px 52px;
    position: relative;
    width: 97%;
    margin: 0 auto;
}

@media all and (max-width: 992px) {
    .rc-section-container.container-1280 {
        padding: 30px 25px 30px 25px;
        width: 100%;
    }
}

@media all and (max-width: 767px) {
    .rc-section-container.container-1280 {
        padding: 20px 12px 20px 12px;
    }
}
