.hamburger-navigation-list {
    display: none;
}

@media all and (max-width: 767px) {
    .hamburger-navigation-list {
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 13px;
    }

    .hamburger-navigation-list__nav {
        margin-top: 15px;
        list-style-type: none;
        width: 100%;
        margin-bottom: 25px;
    }

    .hamburger-navigation-list__nav li {
        margin-top: 30px;
        text-align: center;
    }

    .hamburger-navigation-list__nav li a {
        color: rgb(19, 67, 131);
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
    }

    .hamburger-navigation-list__nav .is-active {
        color: rgb(224, 27, 34);
    }

    .hamburger-navigation-list__options {
        display: flex;
        list-style-type: none;
        justify-content: space-between;
        margin-top: 70px;
    }

    .hamburger-navigation-list__options:hover {
        cursor: pointer;
    }

    .hamburger-navigation-list__options li {
        font-size: 16px;
        color: rgb(19, 67, 131);
        text-align: center;
        font-weight: 500;
    }

    .hamburger-navigation-list__options li.active-language {
        color: rgb(224, 27, 34);
    }

    .hamburger-navigation-list__options li {
        border-right: 1px solid rgb(203, 203, 203);
        margin-right: 24px;
        padding-right: 24px;
    }

    .hamburger-navigation-list__call-center {
        color: rgb(19, 67, 131);
        font-size: 20px;
        font-weight: 500;
    }
}
