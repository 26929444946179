.RC-footer-info {
    display: flex;
    justify-content: space-between;
}

/* .RC-footer-info div, */
.RC-footer-info h4 {
    width: 40%;
}

.RC-footer-info h4 {
    font-size: 18px;
    font-weight: normal;
}
.RC-footer-info__details {
    width: 55%;
    display: flex;
    flex-flow: column wrap;
    max-height: 250px;
}
.RC-footer-info h5,
.RC-footer-info__details p {
    color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 1.43;
    font-weight: normal;
    width: 50%;
    margin-bottom: 10px;
}

.RC-footer-info__id span {
    display: block;
}

@media all and (max-width: 992px) {
    .RC-footer-info h5,
    .RC-footer-info__details p {
        font-size: 14px;
    }

    .RC-footer-info h4 {
        font-size: 16px;
    }
}

@media all and (max-width: 767px) {
    .RC-footer-info {
        flex-direction: column;
    }

    .RC-footer-info h4 {
        width: 100%;
        margin-bottom: 20px;
    }

    .RC-footer-info__details {
        width: 100%;
        flex-flow: column nowrap;
        height: auto;
    }

    .RC-footer-info h5,
    .RC-footer-info__details p {
        font-size: 12px;
        width: 100%;
    }
}
