.header {
    width: 100%;
    height: 100px;
    position: fixed;
    background-color: white;
    z-index: 2;
    top: 0;
    left: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.header:after {
    clear: both;
    content: '';
    display: block;
}

.header--hidden {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.header .container-1280 {
    background-color: rgb(255, 255, 255);
    position: relative;
    display: flex;
    /* flex-flow: row wrap; */
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px 0 26px 0;
    z-index: 3;
    height: 100%;
}

.header__logo {
    margin-bottom: -3px;
    width: 148px;
}

.header__logo img {
    width: 100%;
}

.header__navigation {
    margin-bottom: 6px;
    width: 70%;
}

.header__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.header__content a {
    font-size: 14px;
    color: rgb(136, 136, 136);
    font-weight: normal;
    text-decoration: none;
}

.header__content a span {
    font-weight: 500;
}

.header__content a:hover {
    color: rgb(224, 27, 34);
}

.header__content__options {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    margin-bottom: 9px;
    margin-top: 10px;
}

.header__content__options li {
    font-size: 16px;
    color: rgb(5, 47, 105);
    text-align: center;
    font-weight: 500;
}

.header__content__options li {
    border-right: 2px solid rgb(203, 203, 203);
    margin-right: 13px;
    padding-right: 13px;
}

.header__content__options li:hover {
    color: rgb(224, 27, 34);
    cursor: pointer;
}

.header__content__options li.active-language {
    color: rgb(224, 27, 34);
}

.header__search-menu {
    height: 43%;
}

.header__menu {
    display: none;
}

@media all and (max-width: 1060px) {
    .header .container-1280 {
        padding: 11px 0 18px 0;
        width: 97%;
    }

    .header__content__options {
        margin-bottom: 6px;
    }

    .header__content__options li {
        font-size: 14px;
    }

    .header__content h6 {
        font-size: 13px;
    }

    .header__logo {
        margin-bottom: 0;
        width: 129px;
    }

    .header__navigation {
        width: 65%;
    }
}

@media all and (max-width: 992px) {
    .header__content a {
        font-size: 12px;
    }

    .header__content__options li:hover {
        color: rgb(5, 47, 105);
    }

    .header__navigation {
        width: 55%;
    }
}

@media all and (max-width: 767px) {
    .header {
        height: 50px;
        position: fixed;
        z-index: 2;
    }

    .header .container-1280 {
        align-items: center;
        width: 100%;
    }

    .header .container-1280 {
        padding: 0 14px 0 12px;
    }

    .header__logo {
        width: 88px;
    }

    .header__logo img {
        width: 100%;
    }

    .header__navigation,
    .header__content {
        display: none;
    }

    .header__search-menu {
        display: flex;
        justify-content: space-between;
        width: 60px;
    }

    .header__menu {
        display: block;
        width: 24px;
    }

    .header__menu:hover {
        cursor: pointer;
    }

    .header__menu--close .header__menu__close-menu {
        display: none;
    }

    .header__menu--open .header__menu__close-menu {
        display: inline;
    }

    .header__menu--open .header__menu__hamburger {
        display: none;
    }
}
