.open-balkan-ucesnici-container {
    width: 100%;
    height: 256.8vw;
    background-image: url('/images/open-balkan-ucesnici-mob.jpg');
    background-size: 100% 100%;
}
@media all and (min-width: 1100px) {
    .open-balkan-ucesnici-container {
        height: 56.25vw;
        background-image: url('/images/open-balkan-ucesnici.jpg');
    }
}
.open-balkan-ucesnici-flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
}
@media all and (min-width: 1100px) {
    .open-balkan-ucesnici-flex-container {
        flex-direction: row;
        justify-content: center;
    }
}

.open-balkan-single-ucesnik-wrapper {
    width: 314px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
    padding-top: 18px;
    padding-left: 29px;
    padding-right: 31px;
    padding-bottom: 20px;
}
@media all and (min-width: 1100px) {
    .open-balkan-single-ucesnik-wrapper {
        width: 420px;
        margin-bottom: 0;
        margin-left: 9px;
        margin-right: 9px;
    }
}
.open-balkan-ucesnik-name {
    color: #ffffff;
    font-family: 'Playfair Display';
    font-size: 23px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 0;
    opacity: 0.9;
}

.open-balkan-ucesnik-horizontal-line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.24);
    margin-top: 17px;
    margin-bottom: 17px;
}
.open-balkan-ucesnik-txt {
    color: #ffffff;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 5px;
    opacity: 0.79;
}
