.top-section-title {
    margin-bottom: 50px;
}

.top-section-title--centered {
    text-align: center;
}

.top-section-title__text {
    color: rgb(255, 255, 255);
    font-size: 50px;
    font-weight: 300;
    padding-bottom: 15px;
}

.top-section-title__text.dark {
    color: rgb(5, 47, 105);
}

.top-section-title__text--underlined {
    position: relative;
}

.top-section-title__text--underlined:after {
    content: '';
    width: 56px;
    height: 2px;
    background-color: rgb(224, 27, 34);
    position: absolute;
    left: 0;
    bottom: 0;
}

.top-section-title--centered .top-section-title__text--underlined:after {
    left: calc(50% - 28px);
}

@media all and (max-width: 992px) {
    .top-section-title {
        margin-bottom: 30px;
    }

    .top-section-title__text {
        font-size: 36px;
        padding-bottom: 10px;
    }

    .top-section-title__text--underlined:after {
        width: 40px;
    }

    .top-section-title--centered .top-section-title__text--underlined:after {
        left: calc(50% - 20px);
    }
}

@media all and (max-width: 767px) {
    .top-section-title {
        margin-bottom: 20px;
    }

    .top-section-title__text {
        font-size: 28px;
        padding-bottom: 7px;
    }

    .top-section-title__text--underlined:after {
        width: 30px;
    }

    .top-section-title--centered .top-section-title__text--underlined:after {
        left: calc(50% - 15px);
    }
}
