.single-activity-list-item-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
@media all and (min-width: 720px) {
    .single-activity-list-item-container {
        width: 48%;
    }
}

@media all and (min-width: 1440px) {
    .single-activity-list-item-container {
        width: 32%;
    }
}

.single-activity-list-item-container:hover .single-activity-title-card-wrapper {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
}

.single-activity-list-item-wrapper {
    width: 314px;
    min-height: 440px;
    position: relative;
}
@media all and (min-width: 920px) {
    .single-activity-list-item-wrapper {
        width: 416px;
        min-height: 460px;
    }
}

.single-activity-featured-image {
    width: 100%;
    height: 364px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: transform 0.2s;
}
@media all and (min-width: 868px) {
    .single-activity-featured-image {
        height: 328px;
    }
}

.single-activity-title-card-wrapper {
    width: 287px;
    position: absolute;
    top: 280px;
    left: 0;
    background-color: #fafafa;
    padding: 20px;
}
@media all and (min-width: 868px) {
    .single-activity-title-card-wrapper {
        width: 318px;
        padding: 20px 30px 25px 25px;
    }
}

.single-activity-title-card-title {
    color: #063779;
    font-family: 'Playfair Display';
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 39px;
}

.single-activity-title-card-date-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
}

.single-activity-title-card-date-horizontal-line {
    width: 25px;
    height: 1px;
    background-color: #000000;
    margin-right: 21px;
}

.single-activity-title-card-date {
    color: #000000;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 0;
}
