.pagination {
    width: 230px;
    height: 40px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.pagination__left,
.pagination__page,
.pagination__right {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
}

.pagination__left:hover,
.pagination__right:hover {
    cursor: pointer;
}

.pagination__left__arrow,
.pagination__right__arrow {
    border: solid rgb(255, 255, 255);
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: auto;
}

.pagination__left__arrow {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.pagination__right__arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.pagination__page__number {
    width: 40px;
    height: 36px;
    line-height: 36px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    margin: auto;
}

.pagination__page__number:hover {
    cursor: pointer;
}

.current-page .pagination__page__number {
    color: rgb(224, 27, 34);
}

.disable-pag {
    opacity: 0.3;
}

.pagination__left__arrow.light-pag,
.pagination__right__arrow.light-pag {
    border: solid rgb(5, 47, 105);
    border-width: 0 2.5px 2.5px 0;
}

.pagination__page__number.light-pag {
    color: rgb(5, 47, 105);
}

.current-page .pagination__page__number.light-pag {
    color: rgb(224, 27, 34);
}

@media all and (max-width: 992px) {
    .pagination {
        width: 100%;
        max-width: 288px;
        margin-bottom: 30px;
    }

    .pagination__left,
    .pagination__page,
    .pagination__right {
        border: solid 1px rgb(5, 47, 105);
        background-color: rgb(26, 89, 174);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .pagination__left__arrow,
    .pagination__right__arrow {
        width: 10px;
        height: 10px;
        position: relative;
    }

    .pagination__left__arrow {
        left: 2px;
    }

    .pagination__right__arrow {
        right: 2px;
    }

    .pagination__page__number {
        font-size: 15px;
    }

    .current-page {
        border: 2px solid rgb(224, 27, 34);
        background-color: rgb(255, 255, 255);
    }

    .pagination__left.light-pag,
    .pagination__page.light-pag,
    .pagination__right.light-pag {
        border: solid 1px rgb(228, 228, 228);
        background-color: rgb(247, 247, 247);
    }

    .current-page.light-pag {
        border: 2px solid rgb(224, 27, 34);
        background-color: rgb(255, 255, 255);
    }
}
