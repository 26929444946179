/* .section-button {
    display: flex;
    justify-content: center;
} */

.section-button button {
    padding: 15px 60px;
    background: transparent;
    border: 1px solid rgb(5, 47, 105);
    box-shadow: none;
    font-size: 16px;
    color: rgb(5, 47, 105);
    cursor: pointer;
}

.section-button button:hover {
    background: rgb(5, 47, 105);
    color: rgb(255, 255, 255);
}

.section-button.section-button--dark button {
    border: 1px solid rgb(255, 255, 255);
    margin: 25px 0;
    padding: 14px 52px;
    color: rgb(255, 255, 255);
}

.section-button.section-button--dark button:hover {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

.section-button button:focus {
    outline: none;
}

.section-button__icon {
    margin-right: 20px;
    margin-left: -10px;
}

@media all and (max-width: 992px) {
    .section-button button {
        font-size: 14px;
        line-height: 17px;
        padding: 12px 37px;
    }

    .section-button.section-button--dark button {
        margin: 0;
    }

    .section-button button:hover {
        box-shadow: none;
    }

    .section-button button:hover {
        background-color: rgb(5, 47, 105);
    }
}

@media all and (max-width: 767px) {
    .section-button button {
        font-size: 13px;
        padding: 12px 30px;
    }
}
